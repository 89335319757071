import React, { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X, Wand2, Sparkles } from 'lucide-react';
import logger from '../../logger.js';
import helpers from '../../client__helpers.js';
import toast from 'react-hot-toast';

const TEMPLATE_IDEAS = [
  `Rate my look`,
  `How do I compare vs. my friend (compares two different players)`,
  `**Trash Talk Trainer (Voice):** A sassy commentator offers real-time voice feedback and mockery based on the player's in-game actions, weaving in their appearance for comedic effect and to motivate better plays.`,
  `**Avatar Stylist (Image/Text):** Provides dynamic fashion advice and critiques, creating monster mashups of player stats and gear that would fit within the VR GymClass ecosystem.`,
  `Describe the type of player I am (uses stats)`,
  `**Confidence Booster (Voice):** A motivational presence that binds player achievements with encouraging words, supporting moral and mental aptitude development within the basketball context.`,  
  `Ask a question (uses a 'question' attribute)`,
  `**Interactive Game Historian (Text):** Explores player stats by placing them within mock historical timelines where their achievements are celebrated alongside fabricated lore`,
  `**Virtual Coach's Chalkboard (Image):** Offers visual diagrams and strategies that capitalize on the player's current stats, such as positioning tips for tall players.`,
  `Rate my last game (uses stats and inputs last game stats)`,
];

const GenerateTemplateModal = ({ open, onOpenChange, onTemplateGenerated }) => {
  const [gameDescription, setGameDescription] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [currentIdeaIndex, setCurrentIdeaIndex] = useState(0);

  const cycleIdea = () => {
    const nextIndex = (currentIdeaIndex + 1) % TEMPLATE_IDEAS.length;
    setCurrentIdeaIndex(nextIndex);
    setGameDescription(TEMPLATE_IDEAS[nextIndex]);
  };

  const handleGenerate = () => {
    if (!gameDescription.trim()) {
      toast.error('Please enter a game description');
      return;
    }
    if (isGenerating) { return; }

    setIsGenerating(true);
    return helpers.fetch({
      url: '/api/instant-game/generate-template',
      method: 'POST',
      body: { description: gameDescription }
    }, (err, data) => {
      setIsGenerating(false);
      logger.log('generate-template', 'Template generated %j', {
        data, err
      });

      if (err) {
        toast.error('Failed to generate template');
        return;
      }
      toast.success('Template generated');

      onTemplateGenerated(data.response);
      onOpenChange(false);
    });
  };

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="modal-overlay" />
        <Dialog.Content className="modal-content generate-template-modal">
          <Dialog.Title className="modal-header">
            <Wand2 className="modal-icon" />
            Generate Game Template
          </Dialog.Title>

          <div className="modal-form">
            <div className="form-group">
              <label htmlFor="gameDescription">
                Describe Your Game
                <span className="help-text">What type of game or experience do you want to create?</span>
              </label>
              <textarea
                id="gameDescription"
                className="modal__textarea"
                value={gameDescription}
                onChange={(e) => setGameDescription(e.target.value)}
                placeholder="E.g. A basketball training game that teaches proper shooting form..."
                rows={6}
              />
            </div>

            <div 
              className="button-secondary suggest-button"
              onClick={cycleIdea}
            >
              <Sparkles size={16} />
              Suggest Ideas
            </div>

            <div className="modal-actions">
              <div
                className="button-secondary"
                onClick={() => onOpenChange(false)}
              >
                Cancel
              </div>
              <div
                className={`button-primary ${isGenerating ? 'button-primary--loading' : ''}`}
                onClick={handleGenerate}
              >
                {isGenerating ? (
                  <>
                    <Sparkles className="animate-pulse" size={16} />
                    Generating...
                  </>
                ) : (
                  'Generate Template'
                )}
              </div>
            </div>
          </div>

          <div className="modal-close" onClick={() => onOpenChange(false)}>
            <X size={18} />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default GenerateTemplateModal;