import React, { useState, useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X, Wand2 } from 'lucide-react';
import toast from 'react-hot-toast';
import helpers from '../../client__helpers.js';

const EditPromptsModal = ({ open, onOpenChange, character, onPromptsUpdated }) => {
  const [systemPrompt, setSystemPrompt] = useState('');
  const [userPrompt, setUserPrompt] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    console.log('Character data received:', character?.aiPrompts);
    if (character?.aiPrompts) {
      console.log('Setting prompts:', {
        system: character.aiPrompts.system || '',
        user: character.aiPrompts.user || ''
      });
      setSystemPrompt(character.aiPrompts.system || '');
      setUserPrompt(character.aiPrompts.user || '');
    }
  }, [character]);

  const handleSubmit = (e) => {
    if (isSubmitting) { return; }
    e.preventDefault();
    setIsSubmitting(true);

    return helpers.fetch({
      url: `/api/characters/${character.id}`,
      method: 'PUT',
      body: {
        aiPrompts: {
          system: systemPrompt || '',
          user: userPrompt || '',
        },
      },
    }, (err, data) => {
      setIsSubmitting(false);

      if (err) {
        console.error('Error updating prompts:', err);
        toast.error('Error updating prompts');
        return;
      }

      onPromptsUpdated(data.response);
      toast.success('Prompts updated successfully');
      onOpenChange(false);
    });
  };

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="modal-overlay" />
        <Dialog.Content className="modal-content edit-prompts-modal">
          <Dialog.Title className="modal-header">
            <Wand2 className="modal-icon" />
            Edit AI Prompts
          </Dialog.Title>

          <form onSubmit={handleSubmit} className="modal-form">
            <div className="form-group">
              <label htmlFor="systemPrompt">
                System Prompt
                <span className="help-text">Instructions for how the AI should behave</span>
              </label>
              <textarea
                id="systemPrompt"
                className='modal__textarea'
                value={systemPrompt}
                onChange={(e) => setSystemPrompt(e.target.value)}
                placeholder="You are a wise mentor who speaks in riddles..."
                rows={6}
              />
            </div>

            <div className="form-group">
              <label htmlFor="userPrompt">
                User Context Template
                <span className="help-text">Template for user context. Use e.g. &#123;&#123; player.name &#125;&#125; etc.</span>
              </label>
              <textarea
                id="userPrompt"
                className='modal__textarea'
                value={userPrompt}
                onChange={(e) => setUserPrompt(e.target.value)}
                placeholder="You are talking to {{user.name}}..."
                rows={6}
              />
            </div>

            <div className="modal-actions">
              <button
                type="button"
                className="btn-secondary"
                onClick={() => onOpenChange(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </form>

          <Dialog.Close asChild>
            <button className="modal-close" aria-label="Close">
              <X size={18} />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default EditPromptsModal;