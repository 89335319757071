/**
 * Bragi logger config
 */
const logger = require('bragi-browser');

logger.transports.get('Console').property({ showMeta: false, });
logger.options.groupsDisabled = [ 'silly', 'SocketAPI', ];
logger.options.groupsDisabled = false;

export default logger;
