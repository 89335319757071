//
// logged out view
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shield, Sword, Sparkles, Crown, User, Lock } from 'lucide-react';
import logger from '../../logger.js';
import SHARED_GLOBAL_STATE from '../../shared-global-state.js';
import helpers from '../../client__helpers.js';
import toast from 'react-hot-toast';

const LoggedOutView = () => {
  logger.log('logged-out-view:render', 'LoggedOutView render called');
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    // TODO: Implement login logic

    helpers.fetch({
      url: '/api/login',
      method: 'POST',
      body: { username, password },
    }, (err, data) => {
      if (err) {
        toast.error('Error logging in');
        return false;
      }
      console.log('login response', err, data);
      toast.success('Login functionality coming soon!');
      // login successful
      window.location.href = '/';
    });
  };

  // Add this to determine if form is filled
  const isFormFilled = username.length > 0 && password.length > 0;

  return (
    <div className="app-view--logged-out">
      <div className="hero-container">
        <div className="floating-elements">
          <Shield className="floating-icon shield" size={32} />
          <Sword className="floating-icon sword" size={32} />
          <Crown className="floating-icon crown" size={32} />
          <Sparkles className="floating-icon sparkles" size={32} />
        </div>
        
        <div className="content-wrapper">
          <h1 className="title">NextriaEngine</h1>
          
          <form onSubmit={handleLogin} className="auth-form">
            <div className="input-group">
              <User size={20} className="input-icon" />
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="auth-input"
              />
            </div>

            <div className="input-group">
              <Lock size={20} className="input-icon" />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="auth-input"
              />
            </div>

            <div className="auth-buttons">
              <button 
                type="submit"
                className={`auth-button register-button ${isFormFilled ? 'filled' : ''}`}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoggedOutView;