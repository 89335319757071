import * as Select from '@radix-ui/react-select';
import { Check, ChevronDown } from 'lucide-react';
import { motion } from 'framer-motion';

const StyledSelect = ({ 
  placeholder, 
  value, 
  onValueChange, 
  children,
  className = ''
}) => {
  return (
    <Select.Root value={value} onValueChange={onValueChange}>
      <Select.Trigger 
        className={`select-trigger ${className}`}
        aria-label={placeholder}
      >
        <Select.Value placeholder={placeholder} />
        <Select.Icon className="select-icon">
          <ChevronDown size={16} />
        </Select.Icon>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content className="select-content">
          <Select.Viewport className="select-viewport">
            {children}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

const StyledSelectItem = ({ children, value }) => {
  return (
    <Select.Item value={value} className="select-item">
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator className="select-item-indicator">
        <Check size={16} />
      </Select.ItemIndicator>
    </Select.Item>
  );
};

export { StyledSelect, StyledSelectItem };