import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, MessageSquare, Settings, Trash2, Clock, Play, Square } from 'lucide-react';
import helpers from '../../../client__helpers.js';
import toast from 'react-hot-toast';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

import SoundAPI from '../../../sound.js';
import AudioPlayer from './audio-player.js';
import VoiceSelector from './voice-selector.js';
import EditPromptsModal from '../../modals/edit-prompts';
import ToggleSwitch from '../../ui/toggle';

const DEFAULT_MESSAGE_OBJECT = {
  player: {
    id: '1234',
    relationship: 0.5,
    name: 'Paul',
    appearance: 'hat: yellow banana hat, shirt: red, pants: blue',
    stats: 'strength: 20/30, agility: 10/30, free throw: 30/30'
  }
};

const Character = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [character, setCharacter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingCall, setIsUpdatingCall] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [messages, setMessages] = useState([]);
  const messagesEndRef = React.useRef(null);

  const [newMessage, setNewMessage] = useState(DEFAULT_MESSAGE_OBJECT);

  const [jsonError, setJsonError] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [playingMessageIds, setPlayingMessageIds] = useState(new Set());
  const [isEditPromptsOpen, setIsEditPromptsOpen] = useState(false);
  const [useBuffer, setUseBuffer] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    helpers.fetch({
      url: `/api/characters/${id}`,
    }, (err, data) => {
      if (err) {
        console.error('Error fetching character:', err);
        toast.error('Error fetching character');
        navigate('/characters');
        return;
      }

      setCharacter(data.response);
      setSelectedVoice(data.response.voiceId);
      setIsLoading(false);
    });
  }, [id]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    
    if (!newMessage) { return; }

    const messageObj = JSON.parse(JSON.stringify(newMessage)); // Deep clone

    // Add user message immediately for better UX
    const userMessage = {
      content: JSON.stringify(messageObj, null, 2),
      timestamp: Date.now(),
      isCharacter: false
    };
    
    setMessages(prev => [...prev, userMessage]);
    setIsTyping(true);

    // Determine if we want Buffer or stream mode
    if (!useBuffer) {
      // Original Buffer mode
      helpers.fetch({
        url: `/api/characters/${id}/message`,
        method: 'POST',
        body: { 
          messageObject: messageObj,
        },
      }, handleJsonResponse);
    } else {
      // New streaming mode
      try {
        const response = await fetch(`/api/characters/${id}/message`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            messageObject: messageObj,
            returnBuffer: true
          }),
        });

        if (!response.ok) throw new Error('Network response was not ok');

        // Debug log the response
        console.log('Response headers:', response.headers);
        
        // Create audio context and source
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContext.createBufferSource();

        // Get the array buffer from stream
        const arrayBuffer = await response.arrayBuffer();
        console.log('Received array buffer size:', arrayBuffer.byteLength);
        
        // Debug log before decoding
        console.log('About to decode audio data');
        
        // Decode the audio data
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        
        console.log('Successfully decoded audio buffer:', {
          duration: audioBuffer.duration,
          numberOfChannels: audioBuffer.numberOfChannels,
          sampleRate: audioBuffer.sampleRate
        });
        
        // Create a unique key for this message
        const messageKey = `message-${Date.now()}`;
        
        // Add to SoundAPI with the buffer
        SoundAPI.addSoundFromBuffer({
          key: messageKey,
          buffer: audioBuffer,
          loop: false
        });

        // Add AI response with the audio buffer
        const aiResponse = {
          content: messageObj.message, // You'll need to get the actual response text
          audioDurationMs: audioBuffer.duration * 1000,
          messageKey,
          timestamp: Date.now(),
          isCharacter: true,
          processTime: Date.now() - userMessage.timestamp
        };
        
        setMessages(prev => [...prev, aiResponse]);
        setIsTyping(false);

      } catch (error) {
        console.error('Error:', error);
        toast.error('Error sending message');
        setIsTyping(false);
      }
    }
  };

  const handleJsonResponse = (err, data) => {
    setIsTyping(false);
    if (err) {
      toast.error('Error sending message');
      return;
    }

    const aiResponse = {
      content: data.response.message,
      audioDurationMs: data.response.audioDurationMs,
      audioUrl: data.response.audioUrl,
      timestamp: Date.now(),
      isCharacter: true,
      processTime: data.response.processTime
    };
    setMessages(prev => [...prev, aiResponse]);
  };

  const handleJsonChange = (data) => {
    // Add console.log to debug incoming changes
    console.log('JSON changed:', data);
    
    setJsonError(data.error);
    if (data.jsObject) {
      const newObj = JSON.parse(JSON.stringify(data.jsObject)); // Deep clone
      console.log('Setting new message to:', newObj);
      setNewMessage(newObj);
    }
  };

  const handleVoiceChange = (voiceId) => { 
    setSelectedVoice(voiceId);
    if (isUpdatingCall) { return false; }
    setIsUpdatingCall(true);

    return helpers.fetch({
      url: `/api/characters/${id}`,
      method: 'PUT',
      body: { 
        voiceId: voiceId
      },
    }, (err) => {
      setIsUpdatingCall(false);
      if (err) {
        toast.error('Error updating voice');
        // Revert the selection on error
        setSelectedVoice(character.voiceId);
        return;
      }

      toast.success('Voice updated successfully');
      character.voiceId = voiceId;
      setCharacter(character);
    });
  };

  const handlePlayAudio = (messageId, audioUrl, messageKey) => {
    const soundKey = messageKey || `message-${messageId}`;

    if (playingMessageIds.has(messageId)) {
      // Stop playing
      SoundAPI.stop(soundKey);
      setPlayingMessageIds(prev => {
        const next = new Set(prev);
        next.delete(messageId);
        return next;
      });
    } else {
      // Start playing
      if (!SoundAPI.sounds[soundKey]) {
        if (audioUrl) {
          // Create new sound from URL
          SoundAPI.addSound({
            key: soundKey,
            fileName: audioUrl,
            loop: false
          });
        } else {
          // Sound should already exist from buffer
          console.error('Sound not found:', soundKey);
          return;
        }
      }

      SoundAPI.play(soundKey);
      setPlayingMessageIds(prev => {
        const next = new Set(prev);
        next.add(messageId);
        return next;
      });

      // Remove from playing when done
      SoundAPI.sounds[soundKey].once('end', () => {
        setPlayingMessageIds(prev => {
          const next = new Set(prev);
          next.delete(messageId);
          return next;
        });
      });
    }
  };

  // Update the theme object to match the expected structure
  const darkTheme = {
    colors: {
      background: '#0C0C0C',
      background_warning: '#2E1F1F',
      background_success: '#1F2E1F',
      default: '#00FF00',
      string: '#00FF00',
      number: '#00FF00',
      colon: '#00FF00',
      keys: '#00FF00',
      keys_whiteSpace: '#00FF00',
      primitive: '#00FF00',
      error: '#FF0000',
      bracket: '#00FF00',
    },
    warningMessage: '#F1D624',
    errorMessage: '#FF0000',
  };

  if (isLoading) {
    return <div>Loading character...</div>;
  }

  return (
    <div className="character-page">
      <div className="character-header">
        <button 
          className="back-button"
          onClick={() => navigate('/characters')}
        >
          <ArrowLeft size={20} />
          Back to Characters
        </button>

        {/*
        <div className="character-actions">
          <button className="action-button">
            <Settings size={20} />
            Settings
          </button>
          <button className="action-button delete">
            <Trash2 size={20} />
            Delete
          </button>
        </div>
        */}
      </div>

      <div className="character-content">
        <div className="character-info">
          <div className="character-avatar">
            {character.name[0].toUpperCase()}
          </div>
          <h1>{character.name}</h1>
          <p className="description">{character.description}</p>
          <div className="voice-selector-container">
            <label className='label__with-bottom-border voice-selector-container-label'>Voice</label>
            <VoiceSelector 
              value={selectedVoice} 
              onChange={handleVoiceChange} 
            />
          </div>
          
          <div className='label__with-bottom-border edit-prompts-label'>
            Voice
          </div>
          <button 
            className="edit-prompts-button"
            onClick={() => setIsEditPromptsOpen(true)}
          >
            <Settings size={16} />
            Edit AI Prompts
          </button>

          <div className="output-format-toggle">
            <label className="label__with-bottom-border output-format-toggle__label">Return Raw Audio Wav</label>
            <ToggleSwitch
              id="buffer-toggle"
              checked={useBuffer}
              onCheckedChange={setUseBuffer}
              label="Buffer"
              showLabel={false}
            />
          </div>
          
        </div>

        <div className="chat-section">
          <div className="messages-container">
            {messages.map((message, index) => {
              return (
                <div 
                  key={index} 
                  className={`message ${message.isCharacter ? 'character' : 'user'}`}
                >
                  <div className="message-avatar">
                    {message.isCharacter ? character.name[0].toUpperCase() : 'me'}
                  </div>
                  <div className="message-content">
                    <div className="message-text">
                      {message.content}
                      {message.audioUrl && (
                        <AudioPlayer
                          isPlaying={playingMessageIds.has(index)}
                          onPlayPause={() => handlePlayAudio(index, message.audioUrl)}
                          duration={message.audioDurationMs}
                          soundKey={`message-${index}`}
                        />
                      )}
                      {message.messageKey && (
                        <AudioPlayer
                          isPlaying={playingMessageIds.has(index)}
                          onPlayPause={() => handlePlayAudio(index, null, message.messageKey)}
                          duration={message.audioDurationMs}
                          soundKey={message.messageKey}
                        />
                      )}
                    </div>
                    <div className="message-timestamp">
                      {helpers.timestampToHumanString(message.timestamp)}
                      {message.isCharacter && message.processTime && 
                        <span> · <Clock size={8} /> {(message.processTime / 1000).toFixed(2)}s</span>}
                    </div>
                  </div>
                </div>
              );
            })}
            {isTyping && (
              <div className="message character">
                <div className="message-avatar">
                  {character.name[0].toUpperCase()}
                </div>
                <div className="message-content">
                  <div className="typing-indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          <form onSubmit={handleSendMessage} className="message-input">
            <JSONInput
              id="json-editor"
              placeholder={newMessage}
              locale={locale}
              height="200px"
              width="100%"
              onChange={handleJsonChange}
              waitAfterKeyPress={400} // Add delay to ensure state updates
              onBlur={(data) => handleJsonChange(data)} // Add onBlur handler
              theme={darkTheme}
              style={{
                contentBox: {
                  fontFamily: 'monospace',
                  fontSize: '14px',
                  letterSpacing: '0.5px',
                },
                body: {
                  fontSize: '14px',
                }
              }}
            />
            <button 
              type="submit" 
              disabled={!!jsonError}
              className="send-button"
            >
              <MessageSquare size={20} />
              Send
            </button>
          </form>
        </div>
      </div>

      <EditPromptsModal
        open={isEditPromptsOpen}
        onOpenChange={setIsEditPromptsOpen}
        character={character}
        onPromptsUpdated={(updatedCharacter) => {
          setCharacter(updatedCharacter);
        }}
      />
    </div>
  );
};

export default Character;
