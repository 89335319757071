//
// logged out view
import logger from '../../logger.js';

const LoggedOutView = () => {
  logger.log('loading:render', 'Loading render called');

  return (
    <div className="container">
    </div>
  );
}

export default LoggedOutView;