import React, { useState, useEffect } from 'react';
import * as Plot from "@observablehq/plot";
import helpers from '../../../client__helpers';
import logger from '../../../logger';

const Dashboard = () => {
  const [stats, setStats] = useState({
    totalCharacters: '...',
    analytics: {
      messagesByDay: {},
      soundAPICharactersByDay: {}
    }
  });
  const [error, setError] = useState(null);
  const messagesChartRef = React.useRef();
  const charactersChartRef = React.useRef();

  useEffect(() => {
    const fetchDashboardStats = () => {
      helpers.fetch({
        url: '/api/overview/dashboard',
        method: 'GET'
      }, (err, data) => {
        if (err || (data && data.meta && data.meta.error)) {
          logger.log('error:dashboard', 'Error fetching dashboard stats', err || data.meta);
          setError('Failed to load dashboard statistics');
          return;
        }

        setStats({
          totalCharacters: data.response.countCharacters,
          analytics: data.response.analytics
        });
      });
    };

    fetchDashboardStats();
    const interval = setInterval(fetchDashboardStats, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  // Create chart data arrays
  useEffect(() => {
    if (!stats.analytics) return;

    // Transform data for charts
    const chartData = Object.entries(stats.analytics.messagesByDay).map(([date, count]) => ({
      date: new Date(date),
      messages: count,
      characters: stats.analytics.soundAPICharactersByDay[date] || 0
    })).sort((a, b) => a.date - b.date);

    // Messages Chart
    const messagesChart = Plot.plot({
      style: {
        background: "transparent",
        color: "white",
        fontSize: "12px",
      },
      x: {
        type: "band",
        tickFormat: d => d.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
        label: null
      },
      y: {
        grid: true,
        label: "Messages Sent",
        labelOffset: 40,
        zero: true,
        nice: true
      },
      marks: [
        Plot.areaY(chartData, {
          x: "date",
          y: "messages",
          fill: "rgba(59, 130, 246, 0.15)",
          stroke: "#3b82f6",
          strokeWidth: 2,
          curve: "monotone-y"
        }),
        Plot.dot(chartData, {
          x: "date",
          y: "messages",
          fill: "#3b82f6",
          r: 4
        }),
        Plot.text(chartData, {
          x: "date",
          y: "messages",
          text: d => d.messages.toString(),
          dy: -10,
          fontSize: 11,
          fill: "white"
        })
      ],
      width: messagesChartRef.current?.offsetWidth || 600,
      height: 200,
      marginLeft: 40,
      marginBottom: 30
    });

    // Characters Chart
    const charactersChart = Plot.plot({
      style: {
        background: "transparent",
        color: "white",
        fontSize: "12px",
      },
      x: {
        type: "band",
        tickFormat: d => d.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
        label: null
      },
      y: {
        grid: true,
        label: "Characters Generated",
        labelOffset: 40,
        zero: true,
        nice: true
      },
      marks: [
        Plot.areaY(chartData, {
          x: "date",
          y: "characters",
          fill: "rgba(168, 85, 247, 0.15)",
          stroke: "#a855f7",
          strokeWidth: 2,
          curve: "monotone-y"
        }),
        Plot.dot(chartData, {
          x: "date",
          y: "characters",
          fill: "#a855f7",
          r: 4
        }),
        Plot.text(chartData, {
          x: "date",
          y: "characters",
          text: d => d.characters.toString(),
          dy: -10,
          fontSize: 11,
          fill: "white"
        })
      ],
      width: charactersChartRef.current?.offsetWidth || 600,
      height: 200,
      marginLeft: 40,
      marginBottom: 30
    });

    // Clear and append charts
    if (messagesChartRef.current) {
      messagesChartRef.current.innerHTML = '';
      messagesChartRef.current.appendChild(messagesChart);
    }
    if (charactersChartRef.current) {
      charactersChartRef.current.innerHTML = '';
      charactersChartRef.current.appendChild(charactersChart);
    }
  }, [stats]);

  // Rest of your existing stats calculations...
  const today = new Date().toISOString().split('T')[0];
  const messagesToday = stats.analytics.messagesByDay[today] || 0;
  const charactersToday = stats.analytics.soundAPICharactersByDay[today] || 0;
  const totalMessages = Object.values(stats.analytics.messagesByDay).reduce((sum, count) => sum + count, 0);
  const totalCharacters = Object.values(stats.analytics.soundAPICharactersByDay).reduce((sum, count) => sum + count, 0);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="dashboard">
      <h2 className="text-2xl font-bold mb-6">Dashboard Overview</h2>
      
      {/* Primary Metric */}
      <div className="primary-stat">
        <div className="stat-card">
          <div className="stat-card-header">
            <h3>Total Characters</h3>
            <i className="fas fa-users"></i>
          </div>
          <div className="stat-value">{helpers.formatNumberWithCommas(stats.totalCharacters)}</div>
        </div>
      </div>

      {/* Message Statistics Section */}
      <div className="section-header">
        <h3>Message Analytics</h3>
      </div>
      
      <div className="stats-grid">
        <div className="stat-card">
          <div className="stat-card-header">
            <h3>Messages Today</h3>
            <i className="fas fa-message"></i>
          </div>
          <div className="stat-value">{helpers.formatNumberWithCommas(messagesToday)}</div>
          <div className="text-sm">Last 7 days: {helpers.formatNumberWithCommas(totalMessages)}</div>
        </div>

        <div className="stat-card">
          <div className="stat-card-header">
            <h3>Message Length Today</h3>
            <i className="fas fa-text-width"></i>
          </div>
          <div className="stat-value">{helpers.formatNumberWithCommas(charactersToday)}</div>
          <div className="text-sm">Last 7 days: {helpers.formatNumberWithCommas(totalCharacters)}</div>
        </div>

        <div className="stat-card">
          <div className="stat-card-header">
            <h3>Avg Message Length</h3>
            <i className="fas fa-chart-simple"></i>
          </div>
          <div className="stat-value">
            {totalMessages > 0 
              ? helpers.formatNumberWithCommas(Math.round(totalCharacters / totalMessages))
              : '0'
            }
          </div>
          <div className="text-sm">Characters per message (7 days)</div>
        </div>
      </div>

      <div className="dashboard-charts">
        <div className="chart-container">
          <h3 className="chart-title">Messages Over Time</h3>
          <div ref={messagesChartRef} className="chart" />
        </div>
        
        <div className="chart-container">
          <h3 className="chart-title">Message Length Over Time</h3>
          <div ref={charactersChartRef} className="chart" />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
