import React, { useState, useEffect } from 'react';
import { Play, Square, Volume2 } from 'lucide-react';

const AudioPlayer = ({ isPlaying, onPlayPause, duration, soundKey }) => {
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (isPlaying) {
      setProgress(0);
      const id = setInterval(() => {
        setProgress(prev => {
          if (prev >= 100) {
            clearInterval(id);
            return 100;
          }
          return prev + (100 / (duration || 5000)) * 100;
        });
      }, 100);
      setIntervalId(id);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    };
  }, [isPlaying, duration, soundKey]);

  // Increase radius for better visibility
  const radius = 15;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className="audio-player">
      <button 
        onClick={onPlayPause}
        className="play-button"
      >
        <svg className="progress-ring" width="48" height="48">
          <circle
            className="progress-ring__circle-bg"
            stroke="#ffffff33"
            strokeWidth="4"
            fill="transparent"
            r={radius}
            cx="24"
            cy="24"
          />
          <circle
            className="progress-ring__circle"
            stroke="#3b82f6"
            strokeWidth="4"
            fill="transparent"
            r={radius}
            cx="24"
            cy="24"
            style={{
              strokeDasharray: `${circumference} ${circumference}`,
              strokeDashoffset: strokeDashoffset
            }}
          />
        </svg>
        <div className="play-icon">
          {isPlaying ? (
            <Square size={14} />
          ) : (
            <Play size={14} />
          )}
        </div>
      </button>
    </div>
  );
};

export default AudioPlayer;