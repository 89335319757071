/**
 * Sound API
 * 
 * High level sound management API for handling sound effects and music
 */
import {Howl, Howler} from 'howler';
import logger from './logger.js';
import SHARED_GLOBAL_STATE from './shared-global-state.js';
import helpers from './client__helpers.js';
import analytics from './analytics.js';

const EMPTY_FUNCTION = function () {};

//
// Default vals
const DEFAULT__FADE_IN = 0.1 * 1000;
const DEFAULT__FADE_OUT = 0.05 * 1000;

const MAX_VOLUME = 0.8;

//
// Resume audio context
function resumeAudioContext() {
  if (Howler.ctx && Howler.ctx.state === 'suspended') {
    return Howler.ctx.resume().then(() => {
      logger.log('soundApi/resumeAudioContext', 'Audio context resumed');
    });
  }
  return Promise.resolve();
}

// Add event listener to resume audio context on user interaction
document.addEventListener('click', resumeAudioContext, { once: true });
document.addEventListener('touchstart', resumeAudioContext, { once: true });



//
// define sound api
const SOUND_API = {
  sounds: {},
  play: null, // defined below
  stop: null, // defined below
  playingSounds: [], // keeps track of currently playing sounds
  addSound: null, // defined below
  _state: {}, // keeps track of play state
  _backgroundMusicKey: null, // keeps track of the current background music key

  _MAX_VOLUME: MAX_VOLUME,
  _currentGlobalVolume: MAX_VOLUME,
  
  setGlobalVolume: function setGlobalVolume (volume) {
    logger.log('soundApi/setGlobalVolume', `Master volume set to ${volume}`);
    SOUND_API._currentGlobalVolume = volume;
    Howler.volume(volume);
  },

  setIsSoundEnabled: function soundApiSetIsSoundEnabled (isEnabled, wasFromPlayerAction=false) {
    logger.log('soundApi/setSoundEnabled', `Sound is now ${isEnabled ? 'enabled' : 'disabled'}`);
    SHARED_GLOBAL_STATE.isSoundEnabled = isEnabled;
    Howler.mute(!isEnabled);
    helpers.localStorageSet('isSoundEnabled', '' + isEnabled);

    // ONLY analytics if it was from a player action
    if (wasFromPlayerAction) {
      if (!isEnabled) {
        analytics.track('user:ui:setting:sound:disable', { 
          payload: { 
            currentBackgroundMusicKey: SOUND_API._backgroundMusicKey || '',
            wasFromPlayerAction: wasFromPlayerAction || false,
          } 
        });
      } else {
        analytics.track('user:ui:setting:sound:enable', { 
          payload: { 
            currentBackgroundMusicKey: SOUND_API._backgroundMusicKey || '',
            wasFromPlayerAction: wasFromPlayerAction || false,
          } 
        });
      }
    }
  },

  playBackgroundMusic: function playBackgroundMusic(key) {
    if (Howler.ctx && Howler.ctx.state === 'suspended') {
      logger.log('soundApi/playBackgroundMusic', 'Audio context is suspended. Cannot play background music.');
      return false;
    }

    // Check if the key is the same as the currently playing background music
    if (SOUND_API._backgroundMusicKey === key) {
      logger.log('soundApi/playBackgroundMusic', `Background music with key [${key}] is already playing.`);
      return;
    }

    // Stop the currently playing background music if it exists
    if (SOUND_API._backgroundMusicKey) {
      SOUND_API.stop(SOUND_API._backgroundMusicKey);
    }

    // Play the new background music if a key is provided, otherwise set the key to null
    if (key) {
      SOUND_API._backgroundMusicKey = key;
      SOUND_API.play(key);
    } else {
      SOUND_API._backgroundMusicKey = null;
    }
  },

  playSoundEffect: function playSoundEffect(key) {
    if (Howler.ctx && Howler.ctx.state === 'suspended') {
      logger.log('soundApi/playBackgroundMusic', 'Audio context is suspended. Cannot play background music.');
      return false;
    }
    // Play the new sound effect if a key is provided, otherwise set the key to null
    if (key) {
      SOUND_API.play(key);

      if (SOUND_API.sounds[key]) {
        // Add event listener to clear the key when the sound effect ends
        SOUND_API.sounds[key].once('end', () => {
          SOUND_API._soundEffectKey = null;
          SOUND_API.stop(key);
          logger.log('soundApi/playSoundEffect', `Sound effect with key [${key}] has ended.`);
        });
      }
    }
  },

  // Helper function to write strings to DataView - move inside SOUND_API scope
  _writeString: function(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  },

  addSoundFromBuffer: function soundApiAddSoundFromBuffer(options) {
    const { key, buffer, loop = false, format = 'mp3' } = options;

    if (SOUND_API.sounds[key]) {
      logger.log('soundApi/addSoundFromBuffer', `Sound with key [${key}] already exists. Skipping creation.`);
      return;
    }

    // If it's an MP3 file URL, use it directly
    if (format === 'mp3' && buffer instanceof Blob) {
      const audioUrl = URL.createObjectURL(buffer);
      SOUND_API.sounds[key] = new Howl({
        src: [audioUrl],
        format: ['mp3'],
        volume: MAX_VOLUME,
        loop: loop,
        onload: function() {
          logger.log('soundApi/addSoundFromBuffer', `Sound loaded successfully [${key}]`);
          URL.revokeObjectURL(audioUrl);
        },
        onloaderror: function(id, err) {
          logger.log('error:soundApi/addSoundFromBuffer', `Error loading sound [${key}]: ${err}`);
          URL.revokeObjectURL(audioUrl);
        }
      });
      
      SOUND_API._state[key] = { id: null, isPlaying: false };
      return;
    }

    // Convert AudioBuffer to WAV
    const numberOfChannels = buffer.numberOfChannels;
    const length = buffer.length * numberOfChannels * 2; // 2 bytes per sample
    const wavBuffer = new ArrayBuffer(44 + length);
    const view = new DataView(wavBuffer);
    
    // Write WAV header
    SOUND_API._writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + length, true);
    SOUND_API._writeString(view, 8, 'WAVE');
    SOUND_API._writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numberOfChannels, true);
    view.setUint32(24, buffer.sampleRate, true);
    view.setUint32(28, buffer.sampleRate * numberOfChannels * 2, true);
    view.setUint16(32, numberOfChannels * 2, true);
    view.setUint16(34, 16, true);
    SOUND_API._writeString(view, 36, 'data');
    view.setUint32(40, length, true);

    // Write audio data
    const channels = [];
    for (let i = 0; i < numberOfChannels; i++) {
      channels.push(buffer.getChannelData(i));
    }

    let offset = 44;
    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numberOfChannels; channel++) {
        const sample = Math.max(-1, Math.min(1, channels[channel][i]));
        view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
        offset += 2;
      }
    }

    // Create blob and URL
    const blob = new Blob([wavBuffer], { type: 'audio/wav' });
    const audioUrl = URL.createObjectURL(blob);

    // Create Howl with the audio URL
    SOUND_API.sounds[key] = new Howl({
      src: [audioUrl],
      format: ['wav'],
      volume: MAX_VOLUME,
      loop: loop,
      onload: function() {
        logger.log('soundApi/addSoundFromBuffer', `Sound loaded successfully [${key}]`);
        URL.revokeObjectURL(audioUrl);
      },
      onloaderror: function(id, err) {
        logger.log('error:soundApi/addSoundFromBuffer', `Error loading sound [${key}]: ${err}`);
        URL.revokeObjectURL(audioUrl);
      }
    });

    // Set state
    SOUND_API._state[key] = { id: null, isPlaying: false };
  },
}

// on load, check for localstorage state
let localSoundIsEnabled = helpers.localStorageGet('isSoundEnabled');
// if localstorage state is null or true, set sound enabled. Check for null to default to sound enabled
('' + localSoundIsEnabled === 'true' || localSoundIsEnabled === null) ? SOUND_API.setIsSoundEnabled(true) : SOUND_API.setIsSoundEnabled(false);


//
// Add sound util
SOUND_API.addSound = function soundApiAddSound (options) {
  options = options || {};
  let key = options.key;
  let fileName = options.fileName;

  if (!fileName.match(/\.[^.]+$/)) { fileName += '.mp3'; }

  let fadeInDuration = options.fadeInDuration || DEFAULT__FADE_IN;
  let loop = true;
  if (options.loop === false) { loop = false; }
  logger.log('soundApi/addSound', `addSound: options [${options.key}] %j`, {
    key, fileName, fadeInDuration, loop
  });

  // Check if the sound with the given key already exists
  if (SOUND_API.sounds[key]) {
    logger.log('soundApi/addSound', `Sound with key [${key}] already exists. Skipping creation.`);
    return;
  }

  // create sound
  SOUND_API.sounds[key] = new Howl({ 
    src: [fileName],
    volume: 0,
    loop: loop, // Set loop to true by default
    onload: () => {
      SOUND_API.sounds[key].fade(0, MAX_VOLUME, fadeInDuration);
    },
  });
  // set state
  SOUND_API._state[key] = { id: null, isPlaying: false };
};

SOUND_API.addSounds = function soundApiAddSounds (sounds) {
  sounds = sounds || [];
  sounds.forEach((sound) => {
    SOUND_API.addSound(sound);
  });
  return true;
};

//
//
// Play / Stop
//
SOUND_API.play = function soundApiPlay (key) {
  logger.log('soundApi:play', `[ ${key} ] attempting to play`);
  
  // Check if audio context is suspended
  if (Howler.ctx && Howler.ctx.state === 'suspended') {
    logger.log('soundApi:play', 'Audio context is suspended, attempting to resume');
    Howler.ctx.resume().then(() => {
      // Retry play after resume
      setTimeout(() => soundApiPlay(key), 100);
    });
    return false;
  }

  if (!SOUND_API._state[key]) {
    logger.log('error:soundApi', `soundApiPlay: key not found [${key}]`);
    return false;
  }

  // keep track of currently playing sounds in an array
  if (SOUND_API.playingSounds.indexOf(key) === -1) { 
    SOUND_API.playingSounds.push(key); 
  }

  // if it's playing already, do nothing
  if (SOUND_API._state[key].id || SOUND_API._state[key].isPlaying) {
    return false;
  }

  SOUND_API._state[key].isPlaying = true;
  SOUND_API._state[key].id = SOUND_API.sounds[key].play();
};

SOUND_API.stop = function soundApiStop (key, callback) {
  callback = callback || EMPTY_FUNCTION;
  if (!SOUND_API._state[key]) {
    logger.log('error:soundApi', `soundApiPlay: key not found [${key}]`);
    return false;
  }

  // remove from currently playing sounds
  if (SOUND_API.playingSounds.indexOf(key) !== -1) {
    SOUND_API.playingSounds.splice(SOUND_API.playingSounds.indexOf(key), 1);
  }

  // if it's not playing, do nothing
  if (!SOUND_API._state[key].id) { return false; }

  let id = SOUND_API._state[key].id;
  SOUND_API._state[key].id = null;
  SOUND_API._state[key].isPlaying = false;

  // fade then play success or error
  SOUND_API.sounds[key].once('fade', () => { 
    logger.log('soundApi/stop', `soundApiStop: fade done [${key}]`);
    SOUND_API.sounds[key].stop(id); 
    if (callback) { try { return callback(); } catch (err) {} }
  }, id);
  SOUND_API.sounds[key].fade(SOUND_API.sounds[key].volume(), 0, DEFAULT__FADE_OUT, id);
};

// stop ALL sounds
SOUND_API.stopAll = function soundApiStopAll(callback) {
  callback = callback || EMPTY_FUNCTION;

  // Iterate over all currently playing sounds and stop them
  while (SOUND_API.playingSounds.length > 0) {
    let key = SOUND_API.playingSounds.pop();
    SOUND_API.stop(key, callback);
  }
};

//
//
// export
window.SOUND_API = SOUND_API;

export default SOUND_API;