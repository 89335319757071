import React from 'react';
import * as Select from '@radix-ui/react-select';
import { Check, ChevronDown, ChevronUp } from 'lucide-react';
import SHARED_GLOBAL_STATE from '../../../shared-global-state.js';

const VoiceSelector = ({ value, onChange }) => {
  return (
    <Select.Root value={value} onValueChange={onChange}>
      <Select.Trigger className="voice-selector-trigger">
        <Select.Value placeholder="Select a voice" />
        <Select.Icon>
          <ChevronDown size={20} />
        </Select.Icon>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content className="voice-selector-content">
          <Select.ScrollUpButton className="voice-selector-scroll-button">
            <ChevronUp size={20} />
          </Select.ScrollUpButton>

          <Select.Viewport>
            {SHARED_GLOBAL_STATE.voiceIds.map((voice) => (
              <Select.Item 
                key={voice.id} 
                value={voice.id}
                className="voice-selector-item"
              >
                <Select.ItemText>{voice.name}</Select.ItemText>
                <Select.ItemIndicator className="voice-selector-item-indicator">
                  <Check size={16} />
                </Select.ItemIndicator>
              </Select.Item>
            ))}
          </Select.Viewport>

          <Select.ScrollDownButton className="voice-selector-scroll-button">
            <ChevronDown size={20} />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

export default VoiceSelector;