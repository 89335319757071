/**
 * Fetches user object and sets app store
 */

import logger from '../logger.js';
import storeUser from '../stores/user.js';
import storeUserAuth from '../stores/user-auth.js';
import SHARED_GLOBAL_STATE from '../shared-global-state.js';
import helpers from '../client__helpers.js';

const fetchAppUser = (callback) => {
  callback = callback || function () {};
  fetch('/api/me', {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
    }
  })
  .then(res => {
    if (!res.ok) { 
      throw new Error('Network response was not ok'); 
    }
    return res.json();
  })
  .then(data => {
    logger.log('stores-actions/self-user:returned', 'App user data returned! %j', data && data.response && data.response.user && data.response.user.id);
    if (data && data.response.user && data.meta && data.meta.error !== true) {
      // set user
      storeUser.setState({ user: data.response.user });
      let userProps = data.response.user || {};

      // keep track of auth state. Only mutate if it was NOT already set
      // If we do, it triggers a whole bunch of unnecessary rerenders and stuff
      const currentAuthState = storeUserAuth.getState().isAuthenticated;
      if (!currentAuthState) {
        storeUserAuth.setState({ isAuthenticated: true });
      }

      // update shared global state. This won't be guaranteed to be in sync, 
      // but can be used to check for existence of userIds
      SHARED_GLOBAL_STATE.appUserId = data.response.user.id;
      SHARED_GLOBAL_STATE.appUser = data.response.user;
      SHARED_GLOBAL_STATE.appUsername = data.response.username;

      if (SHARED_GLOBAL_STATE._QUEUED_PUSH_TOKEN) {
        helpers.updatePushTokens(SHARED_GLOBAL_STATE._QUEUED_PUSH_TOKEN);
      }


      // XXXXXXXXXXXXX
      // TODO: REMOVE
      if (window.posthog && window.posthog.identify && (SHARED_GLOBAL_STATE.appUsername || SHARED_GLOBAL_STATE.appUserId)) {
        try {
          window.posthog.identify(
            SHARED_GLOBAL_STATE.appUserId,
            { 
              username: SHARED_GLOBAL_STATE.appUsername, 
              isNativeIOSClient: SHARED_GLOBAL_STATE.isNativeIOSClient,
              numberOfChoicesSelected: userProps.numberOfChoicesSelected,
              numberOfTurnsCompleted: userProps.numberOfTurnsCompleted,
              numberOfChaptersCompleted: userProps.numberOfChaptersCompleted,
              numberOfKeys: userProps.numberOfKeys,
              numberOfKeysUsed: userProps.numberOfKeysUsed,
              level: userProps.level,
              numberOfBooksCreated: userProps.numberOfBooksCreated,
              numberOfBooksCompleted: userProps.numberOfBooksCompleted,
              currencyPrimary: userProps.currencyPrimary,
              initialDeviceType: userProps.initialDeviceType,
            }
          );
        } catch (err) {}
      }
      // TODO: REMOVE
      // XXXXXXXXXXXXX

      return callback(null, data.response.user);

    } else {
      // not logged in!
      storeUserAuth.setState({ isAuthenticated: false });

      SHARED_GLOBAL_STATE.appUserId = null;
      SHARED_GLOBAL_STATE.appUser = null;
      SHARED_GLOBAL_STATE.appUsername = null;

      return callback({error: true, message: 'Not logged in',});
    }
  })
  .catch(error => {
    logger.log('stores-actions/self-user:error', 'Error fetching user data', error);
    storeUserAuth.setState({ isAuthenticated: false });
    try { return callback(null); } catch (err) { }
  });
};

export default {
  fetchAppUser,
};
