/**
 *
 * Utility helpers
 *
 */
import React from 'react';
import { v4 as uuid } from 'uuid';
import logger from './logger.js';

import SHARED_GLOBAL_STATE from './shared-global-state.js';
const MobileDetect = require('mobile-detect');
const invertColor = require('invert-color');

const EMPTY_FUNCTION = function () {};

const LOCAL_STORAGE_PREFIX = 'r-n-r-v2.0.0__';

const HELPERS = {
  // utils for localstorage to prefix a string for versioning purposes
  localStorageGet: function (key) {
    let prefixKey = `${LOCAL_STORAGE_PREFIX}${SHARED_GLOBAL_STATE.appUserId || ''}__`;
    return localStorage.getItem(`${prefixKey}${key}`);
  },
  localStorageSet: function (key, value) {
    let prefixKey = `${LOCAL_STORAGE_PREFIX}${SHARED_GLOBAL_STATE.appUserId || ''}__`;
    return localStorage.setItem(`${prefixKey}${key}`, value);
  },
  localStorageDelete: function (key) {
    // Construct the prefixed key with version and user-specific identifier
    let prefixKey = `${LOCAL_STORAGE_PREFIX}${SHARED_GLOBAL_STATE.appUserId || ''}__`;
    // Remove the item from localStorage
    localStorage.removeItem(`${prefixKey}${key}`);
  },

  _visibility: {
    isVisible: true,

    _setupCalled: false,
    setup: function () {
      if (HELPERS._visibility._setupCalled) { return true; }
      HELPERS._visibility._setupCalled = true;

      let hidden;
      let visibilityChange;
      if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
      } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
      } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
      }

      HELPERS._visibility.hidden = hidden;
      HELPERS._visibility.visibilityChange = hidden;

      document.addEventListener(
        visibilityChange,
        HELPERS._visibility.handleChange,
        false
      );
    },

    handleChange: function () {
      if (document[HELPERS._visibility.hidden]) {
        HELPERS._visibility.isVisible = false;
      } else {
        HELPERS._visibility.isVisible = true;
      }
    },
  },

  //
  // "Mobile" detection - this is really just detecting screen size. If the
  // width is not big enough, different styles are applied. Mobile devices
  // catch this, but so do different sized screens
  //
  isMobile: function () {
    if (
      window._DATA.isMobile ||
      window.innerWidth < SHARED_GLOBAL_STATE._config.SCREEN_WIDTH__FULL_APP
    ) {
      return true;
    } else {
      return false;
    }
  },

  // util - checks based on URL
  isReactNative: function () { return window._APP_DATA.isReactNative; },
  isDesktopApp: function () { return window._APP_DATA.isDesktopApp; },

  // phones
  isPhoneIOS: function () {
    const md = new MobileDetect(window.navigator.userAgent);
    return md.is('iPhone');
  },

  isPhoneAndroid: function () {
    const md = new MobileDetect(window.navigator.userAgent);
    return md.is('AndroidOS');
  },

  isDeviceWindows: function () {
    return (window.navigator.userAgent).toLowerCase().indexOf('windows nt') > -1;
  },

  generateId: function () { return uuid(); },

  formatPhoneNumber: function (number) {
    number = number || '';
    return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6, 10)}`;
  },

  sanitizeName: function (name) {
    name = (name || '')
      .replace(/[^a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]/u, '')
      .replace(/ +/, '')
      .trim();
    name = (name[0] || '').toUpperCase() + name.substring(1);
    return name;
  },

  STATE_NAME_TO_CODE: {
    'Alabama': 'AL', 'Alaska': 'AK', 'American Samoa': 'AS',
    'Arizona': 'AZ', 'Arkansas': 'AR', 'California': 'CA',
    'Colorado': 'CO', 'Connecticut': 'CT', 'Delaware': 'DE',
    'District Of Columbia': 'DC', 'Federated States Of Micronesia': 'FM', 'Florida': 'FL',
    'Georgia': 'GA', 'Guam': 'GU', 'Hawaii': 'HI', 'Idaho': 'ID',
    'Illinois': 'IL', 'Indiana': 'IN', 'Iowa': 'IA', 'Kansas': 'KS',
    'Kentucky': 'KY', 'Louisiana': 'LA', 'Maine': 'ME', 'Marshall Islands': 'MH',
    'Maryland': 'MD', 'Massachusetts': 'MA', 'Michigan': 'MI', 'Minnesota': 'MN',
    'Mississippi': 'MS', 'Missouri': 'MO', 'Montana': 'MT', 'Nebraska': 'NE',
    'Nevada': 'NV', 'New Hampshire': 'NH', 'New Jersey': 'NJ', 'New Mexico': 'NM',
    'New York': 'NY', 'North Carolina': 'NC', 'North Dakota': 'ND', 'Northern Mariana Islands': 'MP',
    'Ohio': 'OH', 'Oklahoma': 'OK', 'Oregon': 'OR', 'Palau': 'PW',
    'Pennsylvania': 'PA', 'Puerto Rico': 'PR', 'Rhode Island': 'RI', 'South Carolina': 'SC',
    'South Dakota': 'SD', 'Tennessee': 'TN', 'Texas': 'TX', 'Utah': 'UT',
    'Vermont': 'VT', 'Virgin Islands': 'VI', 'Virginia': 'VA', 'Washington': 'WA',
    'West Virginia': 'WV', 'Wisconsin': 'WI', 'Wyoming': 'WY',
  },

  STATE_CODE_TO_NAME: {
    'AL': 'Alabama', 'AK': 'Alaska', 'AS': 'American Samoa', 'AZ': 'Arizona', 'AR': 'Arkansas', 'CA': 'California',
    'CO': 'Colorado', 'CT': 'Connecticut', 'DE': 'Delaware', 'DC': 'District Of Columbia',
    'FM': 'Federated States Of Micronesia', 'FL': 'Florida', 'GA': 'Georgia', 'GU': 'Guam', 'HI': 'Hawaii',
    'ID': 'Idaho', 'IL': 'Illinois', 'IN': 'Indiana', 'IA': 'Iowa', 'KS': 'Kansas', 'KY': 'Kentucky', 'LA': 'Louisiana',
    'ME': 'Maine', 'MH': 'Marshall Islands', 'MD': 'Maryland', 'MA': 'Massachusetts', 'MI': 'Michigan', 'MN': 'Minnesota',
    'MS': 'Mississippi', 'MO': 'Missouri', 'MT': 'Montana', 'NE': 'Nebraska', 'NV': 'Nevada', 'NH': 'New Hampshire',
    'NJ': 'New Jersey', 'NM': 'New Mexico', 'NY': 'New York', 'NC': 'North Carolina', 'ND': 'North Dakota',
    'MP': 'Northern Mariana Islands', 'OH': 'Ohio', 'OK': 'Oklahoma', 'OR': 'Oregon', 'PW': 'Palau', 'PA': 'Pennsylvania',
    'PR': 'Puerto Rico', 'RI': 'Rhode Island', 'SC': 'South Carolina', 'SD': 'South Dakota', 'TN': 'Tennessee', 'TX': 'Texas',
    'UT': 'Utah', 'VT': 'Vermont', 'VI': 'Virgin Islands', 'VA': 'Virginia', 'WA': 'Washington', 'WV': 'West Virginia',
    'WI': 'Wisconsin', 'WY': 'Wyoming', },


  // Returns a nicer timestamp string for messages, e.g., "Yesterday at 12:20 pm"
  timestampToHumanString: function timestampToHumanString (timestamp) {
    let timestampDate = new Date(timestamp);
    // TODO: Better way to do this... check for current week and previous week
    let target = new Date(timestamp);

    target.setHours(0);
    target.setMinutes(0);
    target.setSeconds(0, 0);

    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0, 0);

    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0);
    yesterday.setMinutes(0);
    yesterday.setSeconds(0, 0);


    if (target.getTime() === today.getTime()) {
      return `Today at ${timestampDate.toLocaleTimeString().replace(/:[0-9][0-9] /, ' ')}`;

    } else if (target.getTime() === yesterday.getTime()) {
      return `Yesterday at ${timestampDate.toLocaleTimeString().replace(/:[0-9][0-9] /, ' ')}`;

    } else {
      // in past, use timestamp
      return timestampDate.toLocaleDateString();

    }
  },


  getTimeAgoString: function (timestamp, now) {
    if (!now) { now = Date.now(); }

    let laterDate = now >= timestamp ? now : timestamp;
    let previousDate = now < timestamp ? now : timestamp;

    let timeAgo = laterDate - previousDate;

    let secondsAgo = Math.floor(timeAgo / (1000));
    let minutesAgo = Math.floor(timeAgo / (1000 * 60));
    let hoursAgo = Math.floor(timeAgo / (1000 * 60 * 60));
    let daysAgo = Math.floor(timeAgo / (1000 * 60 * 60 * 24));
    let weeksAgo = Math.floor(timeAgo / (1000 * 60 * 60 * 24 * 7));

    // week
    if (hoursAgo > (24 * 7)) {
      return `${weeksAgo} week${weeksAgo > 1 ? 's' : ''} ago`;
    }

    if (hoursAgo > 24) {
      let hourText = '';
      if (hoursAgo % 24 !== 0) { hourText = `${hoursAgo % 24} hours `; }
      return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ${hourText} ago`;
    }
    if (hoursAgo > 0) { return `${hoursAgo} hours ago`; }
    if (minutesAgo > 0) { return `${minutesAgo} minutes ago`; }

    // if it's less than a minute, set it to "now"
    // if (secondsAgo > 0) { return `${secondsAgo}s`; }
    if (secondsAgo < SHARED_GLOBAL_STATE._config.THRESHOLD_ACTIVE) { return 'Online now'; }
    if (secondsAgo > 0) { return `Online now`; }
    return '';
  },

  getTimeAgoStringShort: function (timestamp, now) {
    if (!now) { now = Date.now(); }

    let laterDate = now >= timestamp ? now : timestamp;
    let previousDate = now < timestamp ? now : timestamp;

    let timeAgo = laterDate - previousDate;

    let secondsAgo = Math.floor(timeAgo / (1000));
    let minutesAgo = Math.floor(timeAgo / (1000 * 60));
    let hoursAgo = Math.floor(timeAgo / (1000 * 60 * 60));
    let daysAgo = Math.floor(timeAgo / (1000 * 60 * 60 * 24));
    let weeksAgo = Math.floor(timeAgo / (1000 * 60 * 60 * 24 * 7));

    // Check for years
    const yearsAgo = Math.floor(timeAgo / (1000 * 60 * 60 * 24 * 365));
    if (yearsAgo > 0) {
      return `${yearsAgo}y`;
    }

    // Check for months (approximated to 30 days)
    const monthsAgo = Math.floor(timeAgo / (1000 * 60 * 60 * 24 * 30));
    if (monthsAgo > 0) {
      return `${monthsAgo}mo`;
    }

    // week
    if (hoursAgo > (24 * 7)) {
      return `${weeksAgo} week${weeksAgo > 1 ? 's' : ''} ago`;
    }

    if (hoursAgo > 24) {
      let hourText = '';
      if (hoursAgo % 24 !== 0) { hourText = `${hoursAgo % 24} hours `; }
      return `${daysAgo} day${daysAgo > 1 ? 's' : ''}`;
    }
    if (hoursAgo > 0) { return `${hoursAgo} hours ago`; }
    if (minutesAgo > 0) { return `${minutesAgo} minutes ago`; }

    // if it's less than a minute, set it to "now"
    // if (secondsAgo > 0) { return `${secondsAgo}s`; }
    if (secondsAgo < SHARED_GLOBAL_STATE._config.THRESHOLD_ACTIVE) { return 'Now'; }
    if (secondsAgo > 0) { return `No`; }
    return '';
  },

  getStartTimeRelativeString: function (timestamp, now) {
    if (!now) { now = Date.now(); }
    if (timestamp === now) { return 'Now'; }

    let laterDate = now >= timestamp ? now : timestamp;
    let previousDate = now < timestamp ? now : timestamp;

    let timeAgo = laterDate - previousDate;

    let hasStarted = timestamp < now;

    let secondsAgo = Math.floor(timeAgo / (1000));
    let minutesAgo = Math.floor(timeAgo / (1000 * 60));
    let hoursAgo = Math.floor(timeAgo / (1000 * 60 * 60));
    let daysAgo = Math.floor(timeAgo / (1000 * 60 * 60 * 24));
    let weeksAgo = Math.floor(timeAgo / (1000 * 60 * 60 * 24 * 7));


    // week
    if (hoursAgo > (24 * 7)) {
      return `Start${hasStarted ? 'ed' : 's in'} ${weeksAgo} week${weeksAgo > 1 ? 's' : ''} ${hasStarted ? 'ago' : ''}`;
    }

    if (hoursAgo > 24) {
      let hourText = '';
      if (hoursAgo % 24 !== 0) { hourText = `${hoursAgo % 24} hours `; }
      return `Start${hasStarted ? 'ed' : 's in'} ${daysAgo} day${daysAgo > 1 ? 's' : ''} ${hourText} ${hasStarted ? 'ago' : ''}`;
    }
    if (hoursAgo > 0) { return `Start${hasStarted ? 'ed' : 's in'} ${hoursAgo} hours ${hasStarted ? 'ago' : ''}`; }
    if (minutesAgo > 0) { return `Start${hasStarted ? 'ed' : 's in'} ${minutesAgo} minutes ${hasStarted ? 'ago' : ''}`; }
    // if it's less than a minute, set it to "now"
    if (secondsAgo > 0) { return `Start${hasStarted ? 'ed' : 's in'} ${secondsAgo} second${secondsAgo > 1 ? 's' : ''} ${hasStarted ? 'ago' : ''}`; }
    return '';
  },

  getHumanTimeFromSeconds: function (timeInSeconds, shouldSimplify, shouldHideSeconds) {
    if (!timeInSeconds) { timeInSeconds = 0; }
    shouldSimplify = shouldSimplify === true;
  
    let years = Math.floor(timeInSeconds / (60 * 60 * 24 * 365));
    timeInSeconds -= years * (60 * 60 * 24 * 365);
  
    let months = Math.floor(timeInSeconds / (60 * 60 * 24 * 30));
    timeInSeconds -= months * (60 * 60 * 24 * 30);
  
    let weeks = Math.floor(timeInSeconds / (60 * 60 * 24 * 7));
    timeInSeconds -= weeks * (60 * 60 * 24 * 7);
  
    let days = Math.floor(timeInSeconds / (60 * 60 * 24));
    timeInSeconds -= days * (60 * 60 * 24);
  
    let hours = Math.floor(timeInSeconds / (60 * 60));
    timeInSeconds -= hours * (60 * 60);
  
    let minutes = Math.floor(timeInSeconds / 60);
    timeInSeconds -= minutes * 60;
  
    let seconds = Math.round(timeInSeconds);
    if (shouldHideSeconds) { seconds = 0; }
  
    let parts = [
      { value: years, unit: 'year' },
      { value: months, unit: 'month' },
      { value: weeks, unit: 'week' },
      { value: days, unit: 'day' },
      { value: hours, unit: 'hour' },
      { value: minutes, unit: 'minute' },
      { value: seconds, unit: 'second' },
    ];
  
    let finalString = parts
      .filter(part => part.value > 0)
      .map(part => `${part.value} ${part.unit}${part.value > 1 ? 's' : ''}`)
      .join(', ');
  
    if (shouldSimplify) {
      finalString = finalString
        .replace(' years', 'Y')
        .replace(' year', 'Y')
        .replace(' months', 'MO')
        .replace(' month', 'MO')
        .replace(' weeks', 'W')
        .replace(' week', 'W')
        .replace(' days', 'D')
        .replace(' day', 'D')
        .replace(' hours', 'h')
        .replace(' hour', 'h')
        .replace(' minutes', 'm')
        .replace(' minute', 'm')
        .replace(' seconds', 's')
        .replace(' second', 's')
        .replace(/, ?$/, '')
        .replace(/,/g, '');
    }
  
    if (finalString === '') {
      finalString = 'Now...';
    }
  
    return finalString;
  },

  capitalize: function (input) {
    if (!input) { return ''; }
    return input[0].toUpperCase() + input.substring(1);
  },


  nameFromId: function (id) {
    return id.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  },

  // Dates
  DAYS: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', ],
  MONTHS: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', ],

  getPrefixFromDateNumber: function (dateNumber) {
    dateNumber = dateNumber + '';
    let lastNumber = dateNumber[dateNumber.length-1];

    if (+dateNumber > 9 && +dateNumber < 21) { return 'th'; }
    if (lastNumber === '1') { return 'st'; }
    if (lastNumber === '2') { return 'nd'; }
    if (lastNumber === '3') { return 'rd'; }

    return 'th';
  },

  getTodayString: function () {
    let now = new Date();

    let hours = now.getHours();
    let amOrPm = 'am';

    if (hours >= 12) {
      amOrPm = 'pm';
      hours = hours - 12;
      if (hours === 0) { hours = 12; }
    }
    let minutes = now.getMinutes();
    if (minutes < 10) { minutes = `0${minutes}`; }

    return `${HELPERS.DAYS[now.getDay()]} at ${hours}:${minutes} ${amOrPm}`;
  },

  //
  //
  // format numbers
  formatNumberWithSymbols: function (input, digits) {
    if (digits === undefined) { digits = 1; }
    var si = [
      { value: 1, symbol: '', },
      { value: 1E3, symbol: 'k', },
      { value: 1E6, symbol: 'M', },
      { value: 1E9, symbol: 'B', },
      { value: 1E12, symbol: 'T', },
      { value: 1E15, symbol: 'P', },
      { value: 1E18, symbol: 'E', },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (input >= si[i].value) {
        break;
      }
    }

    return (input / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
  },

  formatNumberWithCommas: function (inputNumber) {
    var parts = (inputNumber || 0).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  },

  getQueryStringFromObject: function (inputObject) {
    return '?' + (Object.keys(inputObject).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(inputObject[key]);
    }).join('&'));
  },


  /**
   *
   * Calculates a string's "size" by turning the string into a strictly
   * alpha-numeric string and calling parseInt('', 36) on each character
   *
   * @param {String} targetString - calculate size of this string
   * @returns {Number} String size
   */
  getStringSize: function (targetString) {
    let size = 0;
    targetString = (targetString + '').toLowerCase();

    // remove any non alpha-numeric characters
    targetString = targetString.replace(/[^a-zA-Z0-9]/g, '');

    for (let i = targetString.length - 1; i >= 0; i--) {
      size += parseInt(targetString[i], 36);
    }

    if (isNaN(size)) {
      size = targetString.length;
    }

    return size;
  },
};


//
//
// Colors Helpers
//
//
HELPERS._COLORS = [
  '#54735e', // light forest green
  '#99DDC8', // teal
  '#95BF74', // not so healthy grass green
  '#283F3B', // dark forest green
  '#659B5E', // healthy grass green
  '#29524A', // fancy green
  '#54735e', // dark pink
  '#DAB6C4', // washed out pinkish purple

  '#1CA8FC',
  // '#EB5558',
  '#E84DBE',
  '#FBA53A',
  '#34ABF6',
  '#47C9DA',
  // '#2BC04C',
  '#36A59A',
  '#9E44EB',
  '#8B6E65',
  '#7A909A',
  '#365257',

  '#FE9000', // orange
  '#FE9000', // yellow
];

HELPERS._BOOK_COVER_COLORS_SUBLTE = [
  '#505050',
  '#2C3E50', // Dark blue-gray
  '#34495E', // Midnight blue
  '#4A4A4A', // Charcoal gray
  '#3E4444', // Dark slate
  '#2C3333', // Deep charcoal
  '#1C2833', // Dark navy
  '#2E0854', // Dark magenta
  '#2F4F4F', // Dark slate gray
  '#3C3C3C', // Asphalt gray
  '#2B2B2B', // Onyx
  '#383838', // Dark gunmetal
  '#2E4053', // Dark cerulean
  '#36454F', // Charcoal blue
  '#353839', // Onyx gray
  '#3F3F3F', // Dark liver
  '#323232', // Jet gray
  '#414A4C', // Dark electric blue
  '#4A0E4E', // Deep purple
  '#4B0082', // Indigo
  '#3C1361', // Dark violet
]
HELPERS._COLORS_LENGTH = HELPERS._COLORS.length;
HELPERS.generateRandomColor = function generateRandomColor () {
  return HELPERS._COLORS[Math.floor(Math.random() * HELPERS._COLORS_LENGTH)];
};

HELPERS.pickColorForString = function pickColorForString (input, colors) {
  input = input || '';
  colors = colors || HELPERS._COLORS;
  return colors[HELPERS.getStringSize(input) % colors.length];
};

HELPERS.invertColor = function (inputColor) {
  return invertColor(inputColor, true);
};

HELPERS.colorForClientLogLevel = function colorForClientLogLevel (input) {
  switch (input) {
    case 1:
      return '#DD2222';
    case 2:
      return '#DCDD83';
    case 4:
      return '#1CA8FC';
    case 16:
      return '#59CA44';
    case 32:
      return '#D0D0D0';
    default:
      return '#D0D0D0';
  }
};

/**
 * Calculates a hex code color string from an arbitrary input string
 * @param {string} input - input string
 */
HELPERS.generateColorFromString = function generateColorFromString (input) {
  input = input || '';
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (var i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

HELPERS.getSlugLower = function getSlugLower (slug) {
  return ('' + slug)
    .trim()
    .replace(/ /g, '-')
    .replace(/[^a-zA-Z0-9_-]/g, '')
    .substring(0, 400)
    .toLowerCase();
};

HELPERS.slugToTitle = function slugToTitle (slug) {
  return slug.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
};

/**
 * Gets a number in seconds and converts it to "2:05" for example
 */
HELPERS.formatSecondsToPadded = function formatSecondsToPadded (input) {
  input = +(input || 0);
  let minutes = Math.floor(input / 60);
  let seconds = Math.floor(input % 60);

  if (seconds < 10) { seconds = `0${seconds}`; }
  if (minutes < 10) { minutes = `0${minutes}`; }
  return `${minutes}:${seconds}`;
};

HELPERS.turnArrayOfNumbersIntoArrayOfPercents = function (targetArray) {
  let sum = targetArray.reduce((a, b) => a + b, 0);
  return targetArray.map((val) => {
    return Math.round((val / sum) * 100);
  });
}

//
//
// Promise / fetch helpers
const fetchWithTimeout = (url, options, timeout = 5000) => {
  logger.log('fetchWithTimeout', 'fetchWithTimeout called:', {url, options, timeout});
  return new Promise((resolve, reject) => {
    fetch(url, options).then(resolve, reject);
    const e = new Error("Server Timeout");
    e.name = "FetchTimeout";
    setTimeout(reject, timeout, e);
  });
};
HELPERS.fetchWithTimeout = fetchWithTimeout;

/**
 * Performs an asynchronous fetch with retry logic.
 * Attempts to fetch data from a URL with specified options. If the fetch fails due to a timeout,
 * it retries the operation up to a specified number of times.
 * 
 * @param {string} url The URL to fetch data from.
 * @param {object} options Fetch API options.
 * @param {number} retries Number of times to retry the fetch on timeout. Defaults to 3.
 * @param {number} timeout Duration in milliseconds before a fetch request times out. Defaults to 5000.
 * @returns {Promise<Response>} The fetch response.
 * @throws {Error} Throws an error if all retry attempts fail or if an error other than timeout occurs.
 */
const asyncFetchWithRetry = async (url, options, retries = 3, timeout = 5000) => {
  let attempt = 0;
  while (attempt < retries) {
    try {
      // Attempt to fetch with the provided timeout.
      const response = await fetchWithTimeout(url, options, timeout);
      return response; // Successful fetch, return the response.
    } catch (err) {
      // Increment attempt counter.
      attempt++;
      // If the error is not a timeout or if we've exhausted all retries, rethrow the error.
      if (err.name !== "FetchTimeout" || attempt === retries) {
        throw err;
      }
      // Log retry attempt for debugging purposes.
      console.error(`Fetch attempt ${attempt} failed, retrying...`, err);
    }
  }
  // If the loop completes without returning, it means all attempts failed.
  throw new Error("All fetch attempts failed.");
};
HELPERS.asyncFetchWithRetry = asyncFetchWithRetry;


//
// Deep compare
import { useEffect, useRef } from 'react';
import _ from 'lodash'; // lodash library

function useDeepCompareEffect(callback, dependencies) {
  const firstRenderRef = useRef(true);
  const dependenciesRef = useRef(dependencies);

  if (!_.isEqual(dependencies, dependenciesRef.current)) {
    dependenciesRef.current = dependencies;
  }

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    return callback();
  }, [dependenciesRef.current]);
}
HELPERS.useDeepCompareEffect = useDeepCompareEffect;



/*
 * Fisher Yates shuffle to randomize items an array
 * @param {Array} inputArray - input array to shuffle.
 * WARNING: Will do an in-place shuffle. If caller does not want shuffle to
 * mutate passed in array, create a new array before calling
 */
HELPERS.shuffle = function shuffle (inputArray) {
  inputArray = inputArray || EMPTY_ARRAY;
  if (inputArray.length < 1) {
    return inputArray;
  }

  let m = inputArray.length;
  let i;
  let temp;

  // start at end of array, pick a random element before it, and replace
  while (m) {
    i = Math.floor(Math.random() * m--);
    temp = inputArray[m];
    inputArray[m] = inputArray[i];
    inputArray[i] = temp;
  }

  return inputArray;
};


const REGEX__EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
HELPERS.validateEmail = function validateEmail (email) {
  return REGEX__EMAIL.test(email);
};


HELPERS.scaleLinear = function scaleLinear (num, in_min, in_max, out_min, out_max) {
  return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
};

// --- 
//
// Network helper
//
// ---
/**
 * Performs a fetch request with specified options and a callback.
 * @param {Object} options - The options for the fetch request.
 * @param {string} options.url - The URL to send the request to.
 * @param {string} options.method - The HTTP method to use for the request.
 * @param {Object} [options.params] - The query parameters to include in the request.
 * @param {Object} [options.body] - The body of the request, for POST or PUT methods.
 * @param {React.MutableRefObject<boolean>} [options.isMountedRef] - A React ref object containing a boolean that represents the mounted state of the component.
 * @param {Function} [options.setIsLoading] - A function to set the loading state during the request.
 * 
 * @param {Function} callback - The callback function to execute after the request is completed.
 * @param {Error} callback.error - The error object if an error occurs, otherwise null.
 * @param {Object} callback.data - The data returned from the request if successful.
 */
HELPERS.fetch = function fetchRequest(options, callback) {
  let url = options.url;
  let method = options.method;
  let params = options.params;
  let body = options.body;
  callback = callback || EMPTY_FUNCTION;

  let setIsLoading = options.setIsLoading || null;

  let fetchOptions = {
    method: method,
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'CSRF-Token': SHARED_GLOBAL_STATE.csrf,
    },
  };
  if (body) { fetchOptions.body = JSON.stringify(options.body); }

  logger.log('helpers/fetchRequest', 'fetchRequest called:', {url, method, params, body, fetchOptions, });

  // trigger loading states
  if (setIsLoading) { setIsLoading(true); }

  fetch(url, fetchOptions)
    .then(async res => {
      // set loading state
      if (setIsLoading) { setIsLoading(false); }

      const responseData = await res.json().catch(() => ({}));

      if (!res.ok) {
        const error = new Error(res.statusText || 'HTTP error');
        error.statusCode = res.status;
        error.responseData = responseData;
        error.type = responseData && responseData.meta && responseData.meta.type;
        error.message = responseData && responseData.meta && responseData.meta.message;
        throw error;
      }

      return responseData;
    })
    .then((data) => {
      // set loading state
      try { if (setIsLoading) { setIsLoading(false); } } catch (e) { }

      // trigger callback
      if (data && data.meta && data.meta.error) {
        return callback(data.meta);
      } else {
        return callback(null, data);
      }
    })
    .catch((error) => {
      // set loading state
      try { if (setIsLoading) { setIsLoading(false); } } catch (err) {}

      // trigger callback with enhanced error object
      return callback({
        error: error.message,
        statusCode: error.statusCode,
        type: error.type,
        responseData: error.responseData
      });
    });
};


//
// PUSH UTIL
let _numPushAttempts = 0;
HELPERS.updatePushTokens = (options) => {
  options = options || {};
  let deviceId = options.deviceId || '';
  let token = options.token || '';
  let type = options.type || 'ios';

  // stop attempting after 30 tries
  if (_numPushAttempts > 30) {
    return false;
  }
  _numPushAttempts++;

  HELPERS.fetch({
    url: '/api/me/push-tokens',
    method: 'PUT',
    body: {
      type,
      deviceId,
      token,
    },
  }, (error, data) => {
    if (error) {
      logger.log('error/updatePushTokens', 'Error updating push token, retrying...', error);
      setTimeout(HELPERS.updatePushTokens, Math.random() * 5000 | 0); // Retry after a delay
    } else {
      logger.log('updatePushTokens', 'Push token updated successfully', data);
    }
  });
};


//
//
HELPERS.calculateProgressToNextDayLoginTimestamp = (nextDayLoginTimestamp) => {
  const now = new Date().getTime();
  const twentyFourHoursAgo = nextDayLoginTimestamp - (
    SHARED_GLOBAL_STATE.DATA.nextDayLoginTimestampDelay || (60 * 1000 * 60 * 8)
  )
  const progress = (now - twentyFourHoursAgo) / (nextDayLoginTimestamp - twentyFourHoursAgo);

  let returnValue = Math.min(Math.max(progress, 0), 1); // Ensures the progress is between 0 and 1
  if (isNaN(returnValue)) { returnValue = 0; }
  return returnValue;
};

//
// precache images
HELPERS.precacheImages = function (images) {
  images = images || [];
  
  // Create an array of promises for each image
  const imagePromises = images.map((image) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        logger.log('precacheImages', 'Image precached successfully:', image);
        resolve();
      };
      img.onerror = (err) => {
        logger.log('error:precacheImages', 'Error precaching image:', image, err);
        reject(err);
      };
    });
  });

  // Return a promise that resolves when all images are loaded
  return Promise.all(imagePromises)
    .then(() => {
      logger.log('precacheImages', 'All images precached successfully');
    })
    .catch((err) => {
      logger.log('error:precacheImages', 'Error precaching some images', err);
    });
};


export default HELPERS;