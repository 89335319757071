import React, { useState, useEffect } from 'react';
import { Plus, ChevronRight, Sparkles } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import CreateCharacterModal from '../../modals/create-character';
import helpers from '../../../client__helpers.js';
import SHARED_GLOBAL_STATE from '../../../shared-global-state.js';
import { formatDistanceToNow } from 'date-fns';

const Characters = () => {
  const navigate = useNavigate();
  const [characters, setCharacters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  useEffect(() => {
    helpers.fetch({
      url: '/api/characters',
    }, (err, data) => {
      if (err) {
        console.error('Error fetching characters:', err);
        setIsLoading(false);
        toast.error('Error fetching characters');
        return;
      }
      setCharacters(data.response.objects || []);
      setIsLoading(false);
    });
  }, []);

  const handleCardClick = (characterId) => {
    navigate(`/characters/${characterId}`);
  };

  // Animation variants for Framer Motion
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    show: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3
      }
    }
  };

  // Add this new function to handle mouse move
  const handleMouseMove = (e, cardElement) => {
    const rect = cardElement.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    cardElement.style.setProperty('--mouse-x', `${x}px`);
    cardElement.style.setProperty('--mouse-y', `${y}px`);
  };

  const formatCreatedAt = (timestamp) => {
    try {
      return `Created ${formatDistanceToNow(new Date(timestamp))} ago`;
    } catch (err) {
      return 'Recently created';
    }
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner">
          <Sparkles className="animate-pulse" size={24} />
          <span>Fetching characters...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="characters-page">
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="characters-header"
      >
        <button 
          className="create-character-btn"
          onClick={() => setIsCreateModalOpen(true)}
        >
          <Plus size={20} />
          <span>Summon New Character</span>
        </button>
      </motion.div>
      
      <motion.div 
        className="characters-grid"
        variants={containerVariants}
        initial="hidden"
        animate="show"
      >
        {characters.length === 0 ? (
          <motion.div 
            className="no-characters"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Sparkles size={32} className="mb-4 text-blue-400" />
            <p>Create your first character to get started!</p>
          </motion.div>
        ) : (
          characters.map(character => (
            <motion.div 
              key={character.id}
              variants={cardVariants}
              whileHover={{ 
                scale: 1.02,
                transition: { duration: 0.2 }
              }}
              className="character-card"
              onClick={() => handleCardClick(character.id)}
              onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}
            >
              <div className="character-card-content">
                <div className="character-avatar">
                  {character.name[0].toUpperCase()}
                </div>
                <div className="character-info">
                  <h3>{character.name}</h3>
                  <p className="character-description">{character.description}</p>
                  <div className="character-metadata">
                    <div className="character-voice">
                      {character.voiceId ? (
                        <div className="voice-badge">
                          🗣️ <span>{(SHARED_GLOBAL_STATE.voiceIdsNameById[character.voiceId] || {}).name}</span>
                        </div>
                      ) : (
                        <div className="voice-badge voice-badge--none">
                          <span>No Voice</span>
                        </div>
                      )}
                    </div>
                    <div className="timestamp-badge">
                      <span>{formatCreatedAt(character.created)}</span>
                    </div>
                  </div>
                </div>
                <ChevronRight className="character-arrow" size={20} />
              </div>
              <div className="character-card-glow" />
            </motion.div>
          ))
        )}
      </motion.div>

      <CreateCharacterModal 
        open={isCreateModalOpen}
        onOpenChange={setIsCreateModalOpen}
        onCharacterCreated={(newCharacter) => {
          setCharacters(prev => [...prev, newCharacter]);
        }}
      />
    </div>
  );
};

export default Characters;
