import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { 
  LayoutDashboard, 
  Users, 
  Scroll,
  MessageSquare,
  Settings,
  Menu,
  Bell,
  LogOut,
  Joystick,

} from 'lucide-react';
import logger from '../../logger.js';
import storeUser from '../../stores/user.js';
import { memo } from 'react';
import Dashboard from './pages/dashboard';
import { useNavigate, useLocation } from 'react-router-dom';
import Character from './pages/character';
import Characters from './pages/characters';
import Games from './pages/games';

const LoggedInView = () => {
  logger.log('logged-in-view:render', 'LoggedInView render called');
  const { user } = storeUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSection, setActiveSection] = useState('dashboard');
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  useEffect(() => {
    const path = location.pathname.split('/')[1] || 'dashboard';
    setActiveSection(path);
  }, [location.pathname]);

  const handleCollapse = useCallback(() => {
    setSidebarCollapsed(prev => !prev);
  }, []);

  const menuItems = useMemo(() => [
    { id: 'dashboard', icon: LayoutDashboard, label: 'Dashboard' },
    { id: 'characters', icon: Users, label: 'Characters' },
    { id: 'games', icon: Joystick, label: 'Games' },
  ], []);

  const renderContent = () => {
    const location = useLocation();
    const characterMatch = location.pathname.match(/^\/characters\/([^/]+)/);
  
    if (characterMatch) {
      return <Character />;
    }
  
    switch (activeSection) {
      case 'dashboard':
        return <Dashboard />;
      case 'characters':
        return <Characters />;
      case 'games':
        return <Games />;
      default:
        return <div>Coming Soon</div>;
    }
  };

  const handleNavigation = (itemId) => {
    setActiveSection(itemId);
    navigate(`/${itemId === 'dashboard' ? '' : itemId}`);
  };

  return (
    <div className="admin-layout">
      {/* Sidebar */}
      <aside className={`admin-sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
        <div className="sidebar-header">
          <div className="logo"></div>
            {!isSidebarCollapsed && <span className='logo-text'>Nextria</span>}
          <button 
            className="collapse-btn"
            onClick={handleCollapse}
            aria-label={isSidebarCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
          >
            <Menu size={20} />
          </button>
        </div>

        <nav className="sidebar-nav">
          {menuItems.map(item => {
            const Icon = item.icon;
            return (
              <button
                key={item.id}
                className={`nav-item ${activeSection === item.id ? 'active' : ''}`}
                onClick={() => handleNavigation(item.id)}
              >
                <Icon size={20} />
                {!isSidebarCollapsed && <span>{item.label}</span>}
              </button>
            );
          })}
        </nav>

        <div className="sidebar-footer">
          <button className="logout-btn">
            <LogOut size={20} />
            {!isSidebarCollapsed && <span>Logout</span>}
          </button>
        </div>
      </aside>

      {/* Main Content */}
      <main className="admin-main">
        <header className="admin-header">
          <div className="header-left">
            <h1>{menuItems.find(i => i.id === activeSection)?.label}</h1>
          </div>

          <div className="header-actions">
            <div className="user-menu">
              <div className="user-avatar">{user?.username?.[0]?.toUpperCase() || 'A'}</div>
              <div className="user-info">
                <span className="user-name">{user?.username || 'Admin'}</span>
                <span className="user-role">Administrator</span>
              </div>
            </div>
          </div>
        </header>

        <div className="admin-content">
          {renderContent()}
        </div>
      </main>
    </div>
  );
}

export default memo(LoggedInView);