// index.js
// CSS
import './css/index.scss';

//
// Entrypoint for app
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import logger from './logger.js';
import SHARED_GLOBAL_STATE from './shared-global-state.js';

// Stores
import storeUser from './stores/user.js';
import storeUserAuth from './stores/user-auth.js';

import SoundAPI from './sound.js';

//
// view
import LoggedOutView from './components/logged-out/index.js';
import LoadingView from './components/logged-out/loading.js';
import LoggedInView from './components/logged-in/index.js';

// Component imports
import helpers from './client__helpers.js';
import appOpen from './stores-actions/app-open.js';
import selfUserStoreAction from './stores-actions/self-user.js';

// get ?clientIsIOS=true param from URL
const queryParams = new URLSearchParams(window.location.search);
logger.log('index/initialLoad', 'Initializing app...', { location: window.location.href, queryParams: queryParams.toString(), });

// Separate Toaster into its own component
const ToasterProvider = () => (
  <Toaster 
    position="top-right" 
    toastOptions={{
      className: 'notifications-root-wrapper'
    }}
  />
);

// App component
const App = () => {
  const { isAuthenticated, login, logout } = storeUserAuth();
  const [isLoading, setIsLoading] = useState(true);

  // Fetch user state on component mount
  useEffect(() => {
    logger.log('app:render:useEffect/login', 'Attempting to login... →');

    const fetchData = (attempt = 1) => {
      setIsLoading(true);

      selfUserStoreAction.fetchAppUser((err, user) => {
        setIsLoading(false);

        if (err) {
          if (attempt < 3) {
            return setTimeout(() => { requestAnimationFrame(() => { fetchData(attempt + 1); }); }, 1000);
          }
          return; // Give up after 3 attempts
        }

        // finally, check for daily rewards
        if (user) { appOpen(); }
      });
    };
    fetchData();
  }, [login]); // anytime login changes, re-fetch user state

  // Render corresponding view
  logger.log('app:render', 'App render called %j', { isLoading, });
  const targetView = useMemo(() => {
    logger.log('app:render/targetView', 'Loading new targetView...', { isLoading, isAuthenticated, });
    if (isLoading) {
      return <LoadingView />;

    } else if (isAuthenticated) {
      return <LoggedInView />;

    } else {
      return <LoggedOutView />;
    }
  }, [isLoading, isAuthenticated]); // Only recompute if isLoading or isAuthenticated changes

  //
  // Core app component view
  const AppContent = React.memo(({ targetView }) => (
    <div className={`app__wrapper--inner-wrapper ${isAuthenticated ? 'app__wrapper--inner-wrapper--logged-in' : 'app__wrapper--inner-wrapper--logged-out'}`}>
      <div id='app-inner'>
        {targetView}
      </div>
    </div>
  ));

  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppContent targetView={targetView} />} />
        <Route path="/characters" element={<AppContent targetView={targetView} />} />
        <Route path="/characters/:id" element={<AppContent targetView={targetView} />} />
        <Route path="/promptLab" element={<AppContent targetView={targetView} />} />
        <Route path="/game" element={<AppContent targetView={targetView} />} />
        <Route path="/games" element={<AppContent targetView={targetView} />} />
        <Route path="/games/:id" element={<AppContent targetView={targetView} />} />
      </Routes>
    </Router>
  )
};

// Create separate roots
const appRoot = createRoot(document.getElementById('app__wrapper'));

// Create and append toast container first
const toastContainer = document.createElement('div');
document.body.appendChild(toastContainer);
const toastRoot = createRoot(toastContainer);

// Separate renders
appRoot.render(<App />);
toastRoot.render(<ToasterProvider />);