import React, { useState, useEffect } from 'react';
import { Play, Settings, Copy, Sparkles, Wand2 } from 'lucide-react';
import locale from 'react-json-editor-ajrm/locale/en';
import AudioPlayer from './audio-player';
import helpers from '../../../client__helpers.js';
import toast from 'react-hot-toast';
import SoundAPI from '../../../sound.js';
import logger from '../../../logger.js';

import { StyledSelect, StyledSelectItem } from '../../ui/select';
import { TEMPLATE_PRESETS, DEFAULT_CONTEXT } from './data/prompt-lab-templates';
import GenerateTemplateModal from '../../modals/generate-template';



const Games = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [systemPrompt, setSystemPrompt] = useState('');
  const [userPrompt, setUserPrompt] = useState('');
  const [context, setContext] = useState(DEFAULT_CONTEXT);
  const [response, setResponse] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [playingMessageIds, setPlayingMessageIds] = useState(new Set());
  const [outputType, setOutputType] = useState('text'); // text, audio, image
  const [responseMeta, setResponseMeta] = useState(null);
  const [hasValidPrompts, setHasValidPrompts] = useState(false);
  const [responseError, setResponseError] = useState(null);
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);

  useEffect(() => {
    setHasValidPrompts(!!userPrompt.trim());
  }, [userPrompt]);

  const handleTemplateSelect = (template) => {
    setSystemPrompt(template.systemPrompt);
    setUserPrompt(template.userPrompt);
    setContext(template.context);
    setSelectedTemplate(template.id);
    setResponse(null);
    setResponseMeta(null);
  };

  const handleContextChange = (e) => {
    try {
      const parsedContext = JSON.parse(e.target.value);
      setContext(parsedContext);
    } catch (error) {
      console.error('Invalid JSON:', error);
    }
  };

  const handleSubmit = () => {
    if (!hasValidPrompts) { 
      toast.error('Please enter a user prompt to continue', { id: 'prompt-error', });
      return; 
    }

    setResponseMeta(null);
    setResponse(null);
    setResponseError(null);

    if (isProcessing) { return; }
    setIsProcessing(true);
    setResponseError(null);

    return helpers.fetch({
      url: '/api/instant-game',
      method: 'POST',
      body: {
        systemPrompt,
        userPrompt,
        contextVariables: context,
        outputType
      }
    }, function (err, data) {
      setIsProcessing(false);

      if (err || !data) {
        console.log('err', err, err.error);
        toast.error(err && err.error ? err.error : 'Error generating response');
        setResponseError(JSON.stringify(err, null, 2));
        return false;
      }
      console.log('response', err, data);
      setResponseError(null);
      setResponse(data.response);
      setResponseMeta(data.meta);
    });
  };

  const handlePlayAudio = (audioUrl) => {
    const soundKey = audioUrl; 

    // Start playing
    if (!SoundAPI.sounds[soundKey]) {
      if (audioUrl) {
        // Create new sound from URL
        SoundAPI.addSound({
          key: soundKey,
          fileName: audioUrl,
          loop: false
        });
      } else {
        // Sound should already exist from buffer
        console.error('Sound not found:', soundKey);
        return;
      }
    }

    SoundAPI.play(soundKey);
    setPlayingMessageIds(prev => {
      const next = new Set(prev);
      next.add(soundKey);
      return next;
    });

    // Remove from playing when done
    SoundAPI.sounds[soundKey].once('end', () => {
      setPlayingMessageIds(prev => {
        const next = new Set(prev);
        next.delete(soundKey);
        return next;
      });
    });
  };

  const handleTemplateGenerated = (template) => {
    logger.log('games/generate-template', 'Template generated %j', template);
    setUserPrompt(template.userPrompt);
    if (template.systemPrompt) { setSystemPrompt(template.systemPrompt); }
    if (template.contextVariables) { setContext(template.contextVariables); }
  };

  return (
    <div className="prompt-lab">
      <div className="prompt-lab__prompt-templates">
        <div className="label__with-bottom-border">Prompt Templates</div>
        <div className="template-presets">
          <button
            className="template-preset template-preset--generate"
            onClick={() => setIsGenerateModalOpen(true)}
          >
            <Wand2 size={16} />
            Generate Template
          </button>
          {TEMPLATE_PRESETS.map(template => (
            <button
              key={template.id}
              className={`template-preset`}
              onClick={() => handleTemplateSelect(template)}
            >
              {template.name}
            </button>
          ))}
        </div>
      </div>

      <div className={`prompt-section ${!hasValidPrompts ? 'prompt-section--warning' : ''}`}>
        <div className="section-label">1. Configure Prompts</div>
        {!hasValidPrompts && (
          <div className="data-info data-info--warning">
            Please enter auser prompt to continue
          </div>
        )}

        {outputType === 'voice' && (
          <div className="data-info data-info--warning">
            Note: For voice output, the prompt MUST specify the output includes a 'msg' data output field. See 'rizzTree' template for an example.
          </div>
        )}
        {outputType === 'image' && (
          <div className="data-info data-info--warning">
            Note: For image output, the prompt MUST specify the output includes a 'img' data output field which is the prompt used to generate the image. See 'paintMe' template for an example.
          </div>
        )}

        <div className="data-info data-info--standard">
          System prompt is optional. 
        </div>
        <div className="data-info data-info--standard">
          Variables defined with {`{{`}variable{`}}`} will be populated from the context data
        </div>
        <div className="prompt-inputs">
          <div className="system-prompt">
            <textarea
              value={systemPrompt}
              onChange={(e) => setSystemPrompt(e.target.value)}
              placeholder="Enter system instructions..."
              className="editor-content"
            />
          </div>
          <div className="user-prompt">
            <textarea
              value={userPrompt}
              onChange={(e) => setUserPrompt(e.target.value)}
              placeholder="Enter template with {{variables}}..."
              className="editor-content"
            />
          </div>
        </div>
      </div>

      <div className="prompt-section context-section">
        <div className="section-label">2. Context Variables</div>
        <div className="data-info data-info--standard">
          Context variables are used to populate the template with dynamic data - your character / game state.
        </div>
        <div className="prompt-inputs">
          <div className="context-input">
            <textarea
              value={JSON.stringify(context, null, 2)}
              onChange={handleContextChange}
              placeholder="Enter context as JSON..."
              className="editor-content"
              spellCheck={false}
            />
          </div>
        </div>
      </div>

      <div className="generate-section">
        <div className="generate-controls">
          <StyledSelect
            placeholder="Select output type"
            value={outputType}
            onValueChange={(value) => {
              setOutputType(value);
              setResponse(null);
              setResponseMeta(null);
            }}
          >
            <StyledSelectItem value="text">Text Output</StyledSelectItem>
            <StyledSelectItem value="voice">Audio Voice Output</StyledSelectItem>
            <StyledSelectItem value="image">Image Output</StyledSelectItem>
          </StyledSelect>
          
          <div 
            className={`button-primary generate-button ${isProcessing ? 'button-primary--loading' : ''} ${!hasValidPrompts ? 'button-primary--disabled' : ''}`}
            onClick={handleSubmit} 
          >
            {isProcessing ? (
              <>
                Generating...
              </>
            ) : (
              <>
                Generate Response
              </>
            )}
          </div>
        </div>
      </div>

      <div className="output-section context-section">
        <div className="section-label">3. Generated Output</div>
        {isProcessing ? (
          <div className="loading-container">
            <Sparkles className="animate-pulse" size={24} />
            <span>Generating response...</span>
          </div>
        ) : response && (
          <>
            {outputType === 'voice' && (
              <AudioPlayer
                isPlaying={playingMessageIds.has(response.audioUrl)}
                onPlayPause={() => handlePlayAudio(response.audioUrl)}
                duration={response.audioDurationMs}
                soundKey={`message-${response._id}`}
              />
            )}
            {outputType === 'image' && <img src={response && response.src} alt="Generated" />}
            {outputType === 'text' && <pre className="editor-content--json">{typeof response === 'string' ? response : JSON.stringify(response, null, 2)}</pre>}
          </>
        )}

        {responseError ? (
          <pre className="data-info data-info--error">
            {responseError}
          </pre>
        ) : null}
        
        {response && outputType !== 'text' ? (
          <div className="response-meta">
            <div className="data-info data-info--standard font__mono">
              {JSON.stringify(response, null, 2)}
            </div>
          </div>
        ) : null}

        {responseMeta ? (
          <div className="response-meta">
            <div className="data-info data-info--standard">
              <strong>Response Time:</strong>
              <span className='font__mono'>
                {' ' + Math.round((responseMeta.responseTime / 1000) * 1000) / 1000} seconds
              </span>
            </div>
            <div className="data-info data-info--standard">
              <strong>Response Meta:</strong>
              <span className='font__mono'>
                {' ' + JSON.stringify(responseMeta, null, 2)}
              </span>
            </div>
          </div>
        ) : null}
      </div>

      <GenerateTemplateModal
        open={isGenerateModalOpen}
        onOpenChange={setIsGenerateModalOpen}
        onTemplateGenerated={handleTemplateGenerated}
      />
    </div>
  );
};

export default Games;
