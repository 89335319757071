/**
 *
 * Admin Tools JS
 *
 *
 */
import logger from './logger.js';
import helpers from './client__helpers.js';
import SHARED_GLOBAL_STATE from './shared-global-state';
let clientId;

// TODO:
// Set device Id and send it over the server
let ANALYTICS_API = {};

let didSeeUnloadEvent = false;
ANALYTICS_API.track = function analyticsTrack (key, properties) {
  properties = properties || {};
  logger.log(`analytics-debug:track:${key}`, 'event logged %j', properties);

  // prepare event
  let event = Object.assign({ 
    type: key, 
    clientId: clientId,
    timestamp: Date.now(),
    deviceType: SHARED_GLOBAL_STATE.deviceType,
    deviceVersion: SHARED_GLOBAL_STATE.deviceVersion,
    userAgent: navigator.userAgent,
    secondsSincePageLoad: (Date.now() - SHARED_GLOBAL_STATE.LOAD_TIME) / 1000,
  }, properties);

  // other props

  // userId
  if (SHARED_GLOBAL_STATE.appUserId) { event.userId = SHARED_GLOBAL_STATE.appUserId; }
  // deviceId
  if (SHARED_GLOBAL_STATE.deviceId) { event.deviceId = SHARED_GLOBAL_STATE.deviceId; }

  if (key === 'user:app:page:close') {
    didSeeUnloadEvent = true;
  }

  if (didSeeUnloadEvent) {
    /*
    const url = '/api/me/client-event-beacon';
    const data = JSON.stringify(event);
    // Send the data using sendBeacon
    navigator.sendBeacon(url, data);
    */
    logger.log('analytics/track', `[ ${key} ] - post page unload, sending message`);

  } else {
    /*
    fetch(`/api/me/client-event`, {
      method: "POST",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'CSRF-Token': SHARED_GLOBAL_STATE.csrf,
      },
      body: JSON.stringify(event),
      keepalive: true,
    })
    .then(res => {
      if (!res.ok) { throw new Error('Network response was not ok'); }
      return res.json();
    })
    .then(data => {
      if (data && data.response) { }
      else { logger.log('error:analytics', 'Error logging event from response data', {err: data.meta}); }
    })
    .catch(error => {
      logger.log('error:analytics', 'Error logging event because of network error', {error});
    });
    */
  }


  // xxx
  //
  // send to posthog
  if (window.posthog && window.posthog.capture) {
    posthog.capture(
      key, 
      Object.assign({ isNativeIOSClient: SHARED_GLOBAL_STATE.isNativeIOSClient, }, properties)
    );
  }

};

//
// INITIALIZE
clientId = helpers.localStorageGet('analytics__clientId');
let eventPayload = {
  referrer: document.referrer,
  queryParams: window.location.search, 
}
if (window.location.search && window.location.search.indexOf('fba=1') > -1) {
  eventPayload.adCampaignId = 'facebook-ad-1';
}
  
if (!clientId) {
  clientId = helpers.generateId();
  helpers.localStorageSet('analytics__clientId', clientId);

  // track - "initialize", first initial load of app (or if user resets app / cookies)
  ANALYTICS_API.track('user:app:initialize', {
    payload: eventPayload,
  });
}

// update globally
SHARED_GLOBAL_STATE.clientId = clientId;
// Set the cookie to have the client ID
document.cookie = `clientId=${clientId}; ${document.cookie}`;


// always trigger page open after the briefest initial load
setTimeout(() => {
  ANALYTICS_API.track('user:app:page:open', { payload: eventPayload, });
}, 2);


window.addEventListener('beforeunload', (event) => {
  ANALYTICS_API.track('user:app:page:close', { payload: eventPayload, });
});

export default ANALYTICS_API;
