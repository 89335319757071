//
// zustand store - User
// Controls user state
//
import {create} from 'zustand';

const storeUser = create(set => ({
  user: null,
  dailyRewards: null,

  updateUser: (update) => set(state => {
    const newUserState = { ...state.user, ...update };
    return { user: newUserState };
  }),
}));

export default storeUser;