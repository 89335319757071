/**
 * Helper utility functions
 *
 */
import { v4 as uuid } from 'uuid';
const TAB_ID = uuid(); // generate unique tabID for each new window

window._ADMIN = window._ADMIN || {};

const DATA = window._DATA || {};
const queryParams = new URLSearchParams(window.location.search);


let voiceIdsNameById = {};
(DATA.voiceIds || []).forEach((voiceId) => {
  voiceIdsNameById[voiceId.id] = voiceId;
});

const api = {
  LOAD_TIME: Date.now(),
  clientId: '',

  voiceIds: DATA.voiceIds || [],
  voiceIdsNameById: voiceIdsNameById,

  configs: DATA.configs || {},

  // csrf token
  csrf: DATA.csrf,
  ak: DATA.ak,

  DATA: DATA,

  assetDomain: DATA.assetDomain,
 

  _IS_GAME_INITIALIZED: false,

  currentViewId: null,

  appUserId: DATA.appUserId || null,
  appUser: null,
  appUsername: DATA.appUsername || null,

  brandName: DATA.brandName,
  tabId: TAB_ID,

  // UI activity
  setUIActive: function (isActive) {
    window._ADMIN.isUIActive = isActive;
  },
  getUIActive: function () {
    return !!window._ADMIN.isUIActive;
  },
};

export default api;
