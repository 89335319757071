/**
 * Fetches user object and sets app store
 */

import logger from '../logger.js';
import storeUser from '../stores/user.js';
import storeUserAuth from '../stores/user-auth.js';
import SHARED_GLOBAL_STATE from '../shared-global-state.js';
import helpers from '../client__helpers.js';

import SelfUserStoreAction from './self-user.js';

const appOpen = (callback = () => {}) => {

  // no op here
  return false;

  const attemptFetch = (attemptsLeft) => {
    fetch('/api/me/app-open', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache': 'no-cache'
      }
    })
    .then(res => {
      if (!res.ok) { throw new Error('Network response was not ok'); }
      return res.json();
    })
    .then(data => {
      logger.log('stores-actions/app-open:returned', 'App user data returned! %j', data && data.response && data.response.user && data.response.user.id);
      if (data && data.meta && data.meta.error !== true) {
        // update store
        let storeUserState = storeUser.getState()

        let didUpdateUser = false;

        if (data.response && data.response.dailyRewards) {
          storeUserState.setDailyRewards(data.response.dailyRewards);
          didUpdateUser = true;
        }

        if (data.response.serverMessage) {
          storeUserState.setServerMessage(data.response.serverMessage);
          didUpdateUser = true;
        }

        if (didUpdateUser) {
          // force a re-fetch of the api me user to keep data up to data
          SelfUserStoreAction.fetchAppUser();
        }

        callback(null, data); // No error, pass data to callback
      } else {
        callback({error: true, message: 'Not logged in'});
      }
    })
    .catch(error => {
      logger.log('stores-actions/app-open:error', 'Error fetching user data', error);
      if (attemptsLeft > 0) {
        setTimeout(() => attemptFetch(attemptsLeft - 1), 1000); // Retry after a delay
      } else {
        callback({error: true, message: 'Failed to fetch user data after multiple attempts'});
      }
    });
  };

  attemptFetch(5); // Attempt to fetch 3 times before giving up
};

export default appOpen;
