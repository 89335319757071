import React from 'react';
import { Switch } from '@radix-ui/react-switch';

const ToggleSwitch = ({ id, checked, onCheckedChange, label, showLabel = true }) => {
  return (
    <div className="toggle-switch">
      <Switch
        id={id}
        className="toggle-switch__button"
        checked={checked}
        onCheckedChange={onCheckedChange}
      />
      {showLabel && (
        <span className="toggle-switch__label">
          {checked ? 'Yes' : 'No'}
        </span>
      )}
    </div>
  );
};

export default ToggleSwitch;