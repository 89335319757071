import React, { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X, Wand2, ChevronDown, ChevronUp } from 'lucide-react';
import toast from 'react-hot-toast';
import helpers from '../../client__helpers.js';

const CreateCharacterModal = ({ open, onOpenChange, onCharacterCreated }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [systemPrompt, setSystemPrompt] = useState('');
  const [userPrompt, setUserPrompt] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const handleSubmit = async (e) => {
    if (isSubmitting) { return; }
    e.preventDefault();
    setIsSubmitting(true);

    return helpers.fetch({
      url: '/api/characters',
      method: 'POST',
      body: {
        name,
        description,
        aiPrompts: {
          system: systemPrompt,
          user: userPrompt,
        },
      },
    }, (err, data) => {
      setIsSubmitting(false);

      if (err) {
        console.error('Error fetching characters:', err);
        toast.error('Error fetching characters');
        return;
      }

      onCharacterCreated(data.response);
      onOpenChange(false);
    });
  };

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="modal-overlay" />
        <Dialog.Content className="modal-content create-character-modal">
          <Dialog.Title className="modal-header">
            <Wand2 className="modal-icon" />
            Create New Character
          </Dialog.Title>
          
          <form onSubmit={handleSubmit} className="modal-form">
            <div className="form-group">
              <label htmlFor="name">Character Name</label>
              <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter character name..."
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Describe your character..."
                rows={4}
                required
              />
            </div>

            <button
              type="button"
              className="advanced-toggle"
              onClick={() => setShowAdvanced(!showAdvanced)}
            >
              {showAdvanced ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
              Advanced AI Settings
            </button>

            {showAdvanced && (
              <div className="advanced-settings">
                <div className="form-group">
                  <label htmlFor="systemPrompt">
                    System Prompt
                    <span className="help-text">Instructions for how the AI should behave</span>
                  </label>
                  <textarea
                    id="systemPrompt"
                    value={systemPrompt}
                    onChange={(e) => setSystemPrompt(e.target.value)}
                    placeholder="You are a wise mentor who speaks in riddles..."
                    rows={4}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="userPrompt">
                    User Context Template
                    <span className="help-text">Template for user context. Use user.name, user.age, etc.</span>
                  </label>
                  <textarea
                    id="userPrompt"
                    value={userPrompt}
                    onChange={(e) => setUserPrompt(e.target.value)}
                    placeholder="You are talking to {{user.name}}..."
                    rows={4}
                  />
                </div>
              </div>
            )}

            <div className="modal-actions">
              <button
                type="button"
                className="btn-secondary"
                onClick={() => onOpenChange(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Creating...' : 'Create Character'}
              </button>
            </div>
          </form>

          <Dialog.Close asChild>
            <button className="modal-close" aria-label="Close">
              <X size={18} />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default CreateCharacterModal;