//
// zustand store - User
// Controls auth state only
//
import {create} from 'zustand';
import logger from '../logger';

const storeUserAuth = create(set => ({
  isAuthenticated: false,
  login: () => set(() => ({ isAuthenticated: true, })),
  logout: () => set(() => ({ isAuthenticated: false, })),
}));

export default storeUserAuth;